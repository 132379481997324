import {
  ENodeType,
  NodeDatabase,
  KeyedNodeGroupData,
  KeyedInputPortData,
  KeyedOutputPortData,
  CV2NodeData,
  NodeDatabaseEntry,
} from "../circuitsv2-types";
import { AddChip } from "circuitsv2/chips/implementations/add-chip";
import { ButtonChip, ButtonChipDesc } from "./custom-chips/button-chip";
import { IChipImplementation } from "./chip-types";
import { UnimplementedChip } from "./other/unimplemented-chip";
import { Nodes } from "../../lib/CircuitsV2Resources/misc/chip_data.json";
import { v4 as uuid } from "uuid";
import {
  AlertChipDesc,
  AlertChip,
} from "circuitsv2/chips/custom-chips/alert-chip";
import { TRIG_CHIP_IMPLEMENTATIONS } from "circuitsv2/chips/implementations/trig-chips";
import { uniq } from "lodash-es";
import { FLOAT_MATH_CHIP_IMPLMENTATIONS } from "circuitsv2/chips/implementations/float-math-chips";
import {
  Hz30Chip,
  Hz30ChipDesc,
} from "circuitsv2/chips/custom-chips/hz30-chip";
import { CONTROL_FLOW_CHIPS } from "circuitsv2/chips/implementations/control-flow-chips";

const CUSTOM_NODES: NodeDatabase = {
  ...ButtonChipDesc,
  ...AlertChipDesc,
  ...Hz30ChipDesc,
};

export const NODE_DATABASE: NodeDatabase = {
  ...CUSTOM_NODES,
  ...Nodes,
};

export const CHIP_IMPLEMENTATIONS: {
  [key in ENodeType]: IChipImplementation<any>;
} = {
  "0ccb153c-dd08-4f22-80fd-9d8c5940928c": AddChip,
  ...TRIG_CHIP_IMPLEMENTATIONS,
  ...FLOAT_MATH_CHIP_IMPLMENTATIONS,
  ...CONTROL_FLOW_CHIPS,
  "4047a8cc-3dcc-4fa8-85a7-41d569b8e547": AlertChip,
  "button-chip": ButtonChip,
  "alert-chip": AlertChip,
  "hz30-chip": Hz30Chip,
};

export function hasChipImplementation(nodeType: ENodeType) {
  return !!CHIP_IMPLEMENTATIONS[nodeType];
}

export function getChipImplementation(
  node: CV2NodeData
): IChipImplementation<any> {
  return CHIP_IMPLEMENTATIONS[node.NodeType] || UnimplementedChip;
}

export function getNodeGroupsForNewChip(type: ENodeType): KeyedNodeGroupData[] {
  const nodeGroups: KeyedNodeGroupData[] = [];

  if (NODE_DATABASE[type]) {
    for (const nodeDescKey of Object.keys(NODE_DATABASE[type].NodeDescs)) {
      const nodeDesc = NODE_DATABASE[type].NodeDescs[nodeDescKey];

      const InputPorts: KeyedInputPortData[] = [];
      const OutputPorts: KeyedOutputPortData[] = [];

      for (const inputKey of Object.keys(nodeDesc.InputPorts)) {
        InputPorts.push({
          Key: uuid(),
          Value: {
            DescId: inputKey,
            DefaultSignalValue: {},
          },
        });
      }

      for (const outputKey of Object.keys(nodeDesc.OutputPorts)) {
        OutputPorts.push({
          Key: uuid(),
          Value: {
            DescId: outputKey,
          },
        });
      }

      nodeGroups.push({
        Key: nodeDescKey,
        Value: {
          InputPorts,
          OutputPorts,
        },
      });
    }
  }

  return nodeGroups;
}

export function getRootLevelCategory(nd: NodeDatabaseEntry) {
  return (nd.NodeFilters[0] && nd.NodeFilters[0].FilterPath[0]) || "";
}

export const ROOT_LEVEL_CATEGORIES = uniq(
  Object.values(NODE_DATABASE).map(getRootLevelCategory)
);

export function getNodeTypeDisplayName(nodeType: ENodeType) {
  return NODE_DATABASE[nodeType]?.ReadonlyName || nodeType;
}

export function getNodeTypeDescription(nodeType: ENodeType) {
  return NODE_DATABASE[nodeType]?.Description || "No Description";
}

export function getNodeFilters(nodeType: ENodeType): string[][] {
  return NODE_DATABASE[nodeType]?.NodeFilters.map((nf) => nf.FilterPath) || [];
}
