import { CV2NodeData, NodeId, PortId } from "../circuitsv2-types";
import {
  IEditorPageContext,
  ILocalCircuitContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import {
  getConnectionsDatasForNode,
  getNodeById,
} from "components/node-canvas/node-graph/node/node-connection/util";
import { getChipImplementation } from "../chips/chip-database";

export interface IScheduleEntry {
  time: number;
  nodeId: NodeId;
  portId: PortId;
}

export interface ILocalExecutionContext {
  contextTime: number;
  schedule: IScheduleEntry[];
  heat: {
    lastHeatReset: number;
    currentHeat: number;
  };
  runtimeStateMap: Map<NodeId, any>;
  scheduleImmediate(nodeId: NodeId, portId: PortId);
  scheduleDelayed(nodeId: NodeId, portId: PortId, delay: number);
}

export function getNewLocalContext(): ILocalExecutionContext {
  return {
    contextTime: 0,
    schedule: [],
    heat: {
      lastHeatReset: 0,
      currentHeat: 0,
    },
    runtimeStateMap: new Map<NodeId, any>(),
    scheduleDelayed(nodeId: NodeId, portId: PortId, delay: number) {
      this.schedule.push({ nodeId, portId, time: this.contextTime + delay });
    },
    scheduleImmediate(nodeId: NodeId, portId: PortId) {
      this.schedule.unshift({ nodeId, portId, time: this.contextTime });
    },
  };
}

export function getInputDataForNode(
  editorContext: IEditorPageContext,
  localContext: ILocalCircuitContext,
  node: CV2NodeData,
  nodeGroupIndex: number,
  portIndex: number
) {
  const port = node.NodeGroups![nodeGroupIndex]!.Value!.InputPorts![portIndex]!;
  const portId = port.Key;

  const connection = getConnectionsDatasForNode(
    editorContext.nodeStateCache,
    node.NodeId
  ).find((c) => c.DstNodeId === node.NodeId && c.DstPortId === portId);

  if (!connection) {
    // TODO: Default value
    return undefined;
  }

  const prevNode = getNodeById(
    editorContext.nodeStateCache,
    connection.SrcNodeId
  );

  if (!prevNode) {
    throw new Error("node does not exists");
  }

  return getOutputDataForNode(editorContext, localContext, prevNode, portId);
}

export function getOutputDataForNode(
  editorContext: IEditorPageContext,
  localContext: ILocalCircuitContext,
  node: CV2NodeData,
  portId: PortId
) {
  const chipDefinition = getChipImplementation(node);

  if (!chipDefinition.getOutputData) {
    throw new Error("pin does not exists");
  }

  return chipDefinition.getOutputData({
    editorContext,
    nodeContext: localContext,
    portId,
    node,
    getInputData(nodeGroupIndex: number, portIndex: number) {
      return getInputDataForNode(
        editorContext,
        localContext,
        node,
        nodeGroupIndex,
        portIndex
      );
    },
  });
}
