import { useCallback, useEffect, useRef, Fragment, useMemo, memo } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { NodeInputList } from "./node-input-list/node-input-list";
import { NodeOutputList } from "./node-output-list/node-output-list";
import {
  useEditorPageContext,
  useLocalCircuitContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import {
  CV2NodeData,
  KeyedInputPortData,
  KeyedOutputPortData,
  CircuitContextData,
  NodeId,
} from "circuitsv2/circuitsv2-types";
import { getChipImplementation } from "circuitsv2/chips/chip-database";
import { getNodeTransformById } from "./node-connection/util";
import {
  IChipImplementation,
  INodeEditorComponentProps,
} from "circuitsv2/chips/chip-types";
import { makeStyles, Paper, IconButton } from "@material-ui/core";
import { getNodeName } from "circuitsv2/chips/chip-utils";
import { useSnapshot } from "valtio";
import { NodeImplementationWarning } from "components/node-canvas/node-graph/node-implementation-warning";
import { throttle } from "lodash-es";
import { useNodeCanvasContext } from "components/node-canvas/node-canvas-context/node-canvas-context";
import { Delete } from "@material-ui/icons";

export interface IDragNodeProps {
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  onStartConnector: (
    node: NodeId,
    port: KeyedOutputPortData,
    index: number
  ) => void;
  onCompleteConnector: (
    node: NodeId,
    port: KeyedInputPortData,
    index: number
  ) => void;
}

type DragNodeRuntimeState = any;

const useStyles = makeStyles({
  node: {
    position: "absolute",
    minWidth: 180,
    border: "1px solid transparent",
    borderRadius: 4,
    backgroundColor: "rgba(93,84,93,0.6)",
    userSelect: "none",
    textAlign: "left",
    lineHeight: "20px",
  },

  actions: {
    position: "absolute",
    top: -44,
    right: -2,
    padding: 5,
    border: "2px solid black",
  },

  header: {
    display: "flex",
    alignItems: "center",
    cursor: "all-scroll",

    // Enables "preventDefault" of touch events on mobiles
    touchAction: "none",

    background: "rgb(93,84,93)",

    paddingLeft: 10,
    paddingRight: 10,

    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,

    borderBottom: "1px solid #353535",

    fontSize: 12,

    paddingTop: 3,
    paddingBottom: 3,

    color: "white",
    fontWeight: 600,
  },

  title: {
    whiteSpace: "nowrap",
  },

  content: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
  },

  additionalContent: {
    textAlign: "center",
    padding: 10,
    paddingTop: 0,
  },
});

export const DragNode = memo(
  ({
    contextProxy,
    node,
    onStartConnector,
    onCompleteConnector,
  }: IDragNodeProps) => {
    const classes = useStyles();

    const editorContext = useEditorPageContext();
    const { nodeOperations, nodeStateCache } = editorContext;

    const { executionContext } = useLocalCircuitContext();

    const {
      transformProxy: canvasTransformProxy,
      selectedNodeProxy,
    } = useNodeCanvasContext();

    const runtimeState = useRef({});

    useEffect(() => {
      executionContext.runtimeStateMap.set(node.NodeId, runtimeState);

      return () => {
        executionContext.runtimeStateMap.delete(node.NodeId);
      };
    }, [executionContext.runtimeStateMap, node.NodeId]);

    const throttledDragHandler = useCallback(
      throttle((ui: DraggableData) => {
        nodeOperations.moveNode(node.NodeId, {
          Id: node.NodeId,
          LocalPosition: {
            X: ui.x,
            Y: ui.y,
          },
        });
      }, 16),
      [nodeOperations, node.NodeId]
    );

    const dragStartPos = useRef({ x: 0, y: 0 });

    const handleDragStart = useCallback(
      (eve: DraggableEvent, ui: DraggableData) => {
        dragStartPos.current = { x: ui.x, y: ui.y };

        eve.stopPropagation();
        eve.preventDefault();
      },
      []
    );

    const handleDrag = useCallback(
      (eve: DraggableEvent, ui: DraggableData) => {
        throttledDragHandler(ui);

        eve.stopPropagation();
        eve.preventDefault();
      },
      [throttledDragHandler]
    );

    const handleDragEnd = useCallback(
      (eve: DraggableEvent, ui: DraggableData) => {
        throttledDragHandler(ui);

        if (
          dragStartPos.current.x === ui.x &&
          dragStartPos.current.y === ui.y
        ) {
          if (selectedNodeProxy.selected === node.NodeId) {
            selectedNodeProxy.selected = null;
          } else {
            selectedNodeProxy.selected = node.NodeId;
          }
        }

        eve.stopPropagation();
        eve.preventDefault();
      },
      [throttledDragHandler, selectedNodeProxy, node.NodeId]
    );

    const nodeTransform = useSnapshot(
      getNodeTransformById(nodeStateCache, node.NodeId)!
    );

    // console.log(nodeTransform, JSON.stringify(nodeTransform.LocalPosition));

    const pos = {
      x: nodeTransform?.LocalPosition?.X || 0,
      y: nodeTransform?.LocalPosition?.Y || 0,
    };
    const memoizedPosition = useMemo(() => pos, [pos.x, pos.y]);

    const draggableRef = useRef(null);

    const chipImplementation: IChipImplementation<DragNodeRuntimeState> = getChipImplementation(
      node
    );

    const canvasTransform = useSnapshot(canvasTransformProxy);

    const handleMouseDown = useCallback(
      (port, index) => onStartConnector(node.NodeId, port, index),
      [node.NodeId, onStartConnector]
    );

    const handleMouseUp = useCallback(
      (port, index) => onCompleteConnector(node.NodeId, port, index),
      [node.NodeId, onCompleteConnector]
    );

    const selectedNode = useSnapshot(selectedNodeProxy);

    const isSelected = selectedNode.selected === node.NodeId;

    const handleBodyClick = useCallback(() => {
      if (selectedNodeProxy.selected === node.NodeId) {
        selectedNodeProxy.selected = null;
      } else {
        selectedNodeProxy.selected = node.NodeId;
      }
    }, [node.NodeId, selectedNodeProxy]);

    const handleDeleteNode = useCallback(() => {
      nodeOperations.removeNode(node.NodeId);
    }, [node.NodeId, nodeOperations]);

    return (
      <Draggable
        position={memoizedPosition}
        handle={"." + classes.header}
        onStart={handleDragStart}
        onStop={handleDragEnd}
        onDrag={handleDrag}
        //@ts-ignore
        nodeRef={draggableRef}
        scale={canvasTransform.scale}
      >
        <section
          ref={draggableRef}
          className={classes.node}
          style={{
            zIndex: 10000,
            border: isSelected ? "2px solid black" : "none",
            margin: isSelected ? -2 : 0,
          }}
        >
          {isSelected && (
            <Paper className={classes.actions}>
              <IconButton onClick={handleDeleteNode} size="small">
                <Delete />
              </IconButton>
            </Paper>
          )}
          <header className={classes.header + " disable-canvas-draggable"}>
            <NodeImplementationWarning nodeType={node.NodeType} />
            <span className={classes.title}>
              {getNodeName(contextProxy, node)}
            </span>
          </header>
          <div className={classes.content} onClick={handleBodyClick}>
            {node.NodeGroups?.map((nodeGroup) => {
              return (
                <Fragment key={nodeGroup.Key}>
                  <NodeInputList
                    ports={nodeGroup.Value?.InputPorts || []}
                    onMouseUp={handleMouseUp}
                    {...{
                      contextProxy,
                      node,
                      nodeGroup,
                    }}
                  />
                  <NodeOutputList
                    ports={nodeGroup.Value?.OutputPorts || []}
                    onMouseDown={handleMouseDown}
                    {...{
                      contextProxy,
                      node,
                      nodeGroup,
                    }}
                  />
                </Fragment>
              );
            })}
          </div>
          {chipImplementation.NodeEditorComponent ? (
            <div className={classes.additionalContent}>
              {chipImplementation.NodeEditorComponent({
                node,
                runtimeState,
                executionContext,
                editorContext,
              } as INodeEditorComponentProps<DragNodeRuntimeState>)}
            </div>
          ) : null}
        </section>
      </Draggable>
    );
  }
);
