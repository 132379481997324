import { runtimeConfig } from "service/runtimeConfig";

async function get(url: string) {
  const response = await fetch(url);
  const text = await response.text();

  if (response.status !== 200) {
    throw new Error(text);
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

export interface ISharedCircuit {
  dataUrl: string;
  description: string;
  name: string;
  thumbnailUrl: string;
  uploadedAt: string;

  previousVersion: string;
}

export function getVersion(versionId): Promise<ISharedCircuit> {
  return get(runtimeConfig.BLOB_URL + versionId);
}
