import { IChipImplementation } from "circuitsv2/chips/chip-types";
import { ENodeType, NodeDatabaseEntry } from "circuitsv2/circuitsv2-types";
import { useEffect } from "react";
import { setInterval } from "timers";

export const Hz30ChipDesc: Record<ENodeType, NodeDatabaseEntry> = {
  "hz30-chip": {
    ReadonlyName: "30Hz Chip",
    Description: "Useful until proper events are implemented",
    NodeDescs: {
      "hz30-chip": {
        Name: "30Hz Chip",
        ReadonlyTypeParams: {},
        InputPorts: {},
        OutputPorts: {
          "hz30-chip-exec-out": {
            Name: "30Hz",
            Description: "",
            ReadonlyType: "exec",
          },
        },
      },
    },
    NodeFilters: [
      {
        FilterPath: ["RRCS Chips"],
      },
    ],
  },
};

export const Hz30Chip: IChipImplementation<void> = {
  NodeEditorComponent({ node, executionContext }): JSX.Element | null {
    useEffect(() => {
      const interval = setInterval(() => {
        executionContext.scheduleDelayed(
          node.NodeId,
          node.NodeGroups![0].Value!.OutputPorts![0].Key,
          0
        );
      }, 1000 / 30);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return null;
  },
};
