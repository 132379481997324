import { Spline } from "./spline";
import { useCallback, memo } from "react";
import { NodeConnectionData } from "circuitsv2/circuitsv2-types";
import { useEditorPageContext } from "pages/editor-page/editor-page-context/editor-page-context";
import { useNodeCanvasContext } from "components/node-canvas/node-canvas-context/node-canvas-context";
import { useSnapshot } from "valtio";
import { usePortType, getTypeColor } from "circuitsv2/type-tree/type-utils";

interface INodeConnectionProps {
  connection: NodeConnectionData;
}

const DEFAULT_POS = { x: 0, y: 0 };

export const NodeConnection = memo(({ connection }: INodeConnectionProps) => {
  const { nodeOperations } = useEditorPageContext();

  const { portPositionsProxy } = useNodeCanvasContext();

  const portPositions = useSnapshot(portPositionsProxy);

  const splinestart = portPositions[connection.SrcPortId] || DEFAULT_POS;
  const splineend = portPositions[connection.DstPortId] || DEFAULT_POS;

  const removeNode = useCallback(() => {
    nodeOperations.disconnect(connection);
  }, [nodeOperations, connection]);

  const type = usePortType(connection.DstPortId);

  return (
    <Spline
      start={splinestart}
      end={splineend}
      onRemove={removeNode}
      color={getTypeColor(type)}
    />
  );
});
