import { IChipImplementation } from "../chip-types";
import { ENodeType, PortId, NodeDatabaseEntry } from "../../circuitsv2-types";

export const AlertChipDesc: Record<ENodeType, NodeDatabaseEntry> = {
  "alert-chip": {
    ReadonlyName: "Alert Chip",
    Description: "Alerts",
    NodeDescs: {
      "alert-chip": {
        Name: "Alert Chip",
        ReadonlyTypeParams: {},
        InputPorts: {
          "alert-chip-exec-in": {
            Name: "Alert",
            Description: "",
            ReadonlyType: "exec",
          },
          "alert-chip-message": {
            Name: "Message",
            Description: "",
            ReadonlyType: "any",
          },
        },
        OutputPorts: {
          "alert-chip-exec-out": {
            Name: "Alert",
            Description: "",
            ReadonlyType: "exec",
          },
        },
      },
    },
    NodeFilters: [
      {
        FilterPath: ["RRCS Chips"],
      },
    ],
  },
};

export const AlertChip: IChipImplementation<void> = {
  execute(
    portId: PortId,
    { editorContext, localContext, getInputData, getExecJobForOutputPin }
  ) {
    const message = getInputData(0, 1);
    localContext.alertInterface!.showMessage!("Alert Node: " + message);

    return getExecJobForOutputPin(0, 0);
  },
};
