import { IChipImplementation } from "../chip-types";
import { ENodeType, NodeDatabaseEntry } from "../../circuitsv2-types";

export const ButtonChipDesc: Record<ENodeType, NodeDatabaseEntry> = {
  "button-chip": {
    ReadonlyName: "Button Chip",
    Description: "A button",
    NodeDescs: {
      "button-chip": {
        Name: "Button Chip",
        ReadonlyTypeParams: {},
        InputPorts: {},
        OutputPorts: {
          "button-chip-exec": {
            Name: "OnPress",
            Description: "",
            ReadonlyType: "exec",
          },
        },
      },
    },
    NodeFilters: [
      {
        FilterPath: ["RRCS Chips"],
      },
    ],
  },
};

export const ButtonChip: IChipImplementation<void> = {
  NodeEditorComponent({ node, executionContext }) {
    return (
      <button
        onClick={() => {
          executionContext.scheduleImmediate(
            node.NodeId,
            node.NodeGroups![0].Value!.OutputPorts![0].Key
          );
        }}
      >
        Click me!
      </button>
    );
  },
};
