import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ff9100",
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        position: "absolute !important" as any,
        bottom: 10,
        left: "50%",
        transform: "translateX(-50%)",
      },
    },

    MuiButton: {
      root: {
        textTransform: "none",
      },
    },

    MuiTooltip: {
      tooltip: {
        fontSize: 13,
      },
    },
  },
});
