import { IChipImplementation } from "circuitsv2/chips/chip-types";

export const AbsChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.abs(getInputData(0, 0) || 0);
  },
};

export const CeilChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.ceil(getInputData(0, 0) || 0);
  },
};

export const FloorChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.ceil(getInputData(0, 0) || 0);
  },
};

export const FLOAT_MATH_CHIP_IMPLMENTATIONS = {
  "98b99011-9be8-43b3-89cc-1e9d55bd8b51": AbsChip,
  "f4cbc476-bc98-4cbf-bf47-de4baedddf79": CeilChip,
  "38b502dc-dd35-4083-a2b0-8a8e5f69a958": CeilChip,
  "c90d9339-cdce-4d22-a885-f142fd5567ab": FloorChip,
};
