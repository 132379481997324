import { NodeInput } from "./node-input/node-input";
import { makeStyles } from "@material-ui/core";
import {
  KeyedInputPortData,
  CircuitContextData,
  CV2NodeData,
  KeyedNodeGroupData,
} from "circuitsv2/circuitsv2-types";

interface INodeInputListProps {
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  nodeGroup: KeyedNodeGroupData;
  ports: KeyedInputPortData[];
  onMouseUp: (port: KeyedInputPortData, index: number) => void;
}

const useStyles = makeStyles({
  list: { listStyle: "none", padding: 0, marginTop: 0, marginBottom: 10 },
});

export function NodeInputList({
  contextProxy,
  node,
  nodeGroup,
  ports,
  onMouseUp,
}: INodeInputListProps) {
  const classes = useStyles();

  return (
    <div>
      <ul className={classes.list}>
        {ports.map((port, index) => {
          return (
            <NodeInput
              onMouseUp={() => onMouseUp(port, index)}
              key={port.Key}
              {...{
                contextProxy,
                node,
                nodeGroup,
                port,
              }}
            />
          );
        })}
      </ul>
    </div>
  );
}
