import { NodeOutput } from "./node-output/node-output";
import { makeStyles } from "@material-ui/core";
import {
  KeyedOutputPortData,
  CircuitContextData,
  CV2NodeData,
  KeyedNodeGroupData,
} from "circuitsv2/circuitsv2-types";

interface INodeOutputListProps {
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  nodeGroup: KeyedNodeGroupData;
  ports: KeyedOutputPortData[];
  onMouseDown: (port: KeyedOutputPortData, index: number) => void;
}

const useStyles = makeStyles({
  list: { listStyle: "none", padding: 0, marginTop: 0, marginBottom: 10 },
});

export function NodeOutputList({
  onMouseDown,
  contextProxy,
  node,
  nodeGroup,
  ports,
}: INodeOutputListProps) {
  const classes = useStyles();

  return (
    <div>
      <ul className={classes.list}>
        {ports.map((port, index) => {
          return (
            <NodeOutput
              onMouseDown={() => onMouseDown(port, index)}
              key={port.Key}
              {...{
                contextProxy,
                node,
                nodeGroup,
                port,
              }}
            />
          );
        })}
      </ul>
    </div>
  );
}
