import { IChipImplementation } from "circuitsv2/chips/chip-types";
import { PortId } from "circuitsv2/circuitsv2-types";

export const IfChip: IChipImplementation<void> = {
  execute(portId: PortId, { getInputData, getExecJobForOutputPin }) {
    if (getInputData(0, 1)) {
      return getExecJobForOutputPin(0, 0);
    } else {
      return getExecJobForOutputPin(0, 1);
    }
  },
};

export const CONTROL_FLOW_CHIPS = {
  "7adc6ab1-fcab-4746-aec6-006352e29cdc": IfChip,
};
