import { useCallback, useEffect } from "react";
import {
  useEditorPageContext,
  useLocalCircuitContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import { getChipImplementation } from "../chips/chip-database";
import {
  getNodeById,
  getConnectionsOnOutputPort,
  getOutputPortByIndex,
} from "components/node-canvas/node-graph/node/node-connection/util";
import { getInputDataForNode } from "../context/local-context";
import { getDefaultNextExecutionNodeId } from "../connection/connection";
import { useTypeTreeContext } from "circuitsv2/type-tree/type-tree-context";
import { isOutputPort } from "circuitsv2/chips/chip-utils";
import { ExecJob } from "circuitsv2/chips/chip-types";

const SCHEDULER_INTERVAL = 1000 / 60;

export function ChipScheduler() {
  const editorContext = useEditorPageContext();
  const { simulationSettings, nodeStateProxy, nodeStateCache } = editorContext;

  const localContext = useLocalCircuitContext();
  const { executionContext, tickStatsProxy } = localContext;

  const typeTreeContext = useTypeTreeContext();

  const executeTick = useCallback(() => {
    executionContext.contextTime +=
      SCHEDULER_INTERVAL * simulationSettings.timeScale;

    const jobsToExecute: ExecJob[] = executionContext.schedule
      .filter((entry) => entry.time < executionContext.contextTime)
      .map((entry) => [entry.nodeId, entry.portId]);

    executionContext.schedule = executionContext.schedule.filter(
      (entry) => entry.time >= executionContext.contextTime
    );

    executionContext.heat.lastHeatReset = executionContext.contextTime;
    executionContext.heat.currentHeat = 0;

    const startTime = Date.now();

    while (jobsToExecute.length) {
      let job = jobsToExecute.shift();

      if (job) {
        let [nodeIdToExecute, portIdToExecute] = job;

        const nodeToExecute = getNodeById(nodeStateCache, nodeIdToExecute)!;

        if (isOutputPort(nodeToExecute, portIdToExecute)) {
          const connections = getConnectionsOnOutputPort(
            nodeStateCache,
            nodeIdToExecute,
            portIdToExecute
          );

          if (connections.length) {
            jobsToExecute.unshift([
              connections[0].DstNodeId,
              connections[0].DstPortId,
            ]);
          }
        } else {
          const chipDefinition = getChipImplementation(nodeToExecute);

          executionContext.heat.currentHeat++;
          if (executionContext.heat.currentHeat > simulationSettings.maxHeat) {
            localContext.alertInterface!.showMessage!(
              "Execution Limit reached!"
            );
            jobsToExecute.splice(0, jobsToExecute.length);
            break;
          }

          if (chipDefinition.execute) {
            const nextJob: ExecJob | void = chipDefinition.execute(
              portIdToExecute,
              {
                runtimeState:
                  executionContext.runtimeStateMap.get(nodeToExecute.NodeId) ||
                  {},
                localContext,
                editorContext,
                typeTreeContext,
                node: nodeToExecute,
                getInputData(nodeGroupIndex: number, portIndex: number) {
                  return getInputDataForNode(
                    editorContext,
                    localContext,
                    nodeToExecute,
                    nodeGroupIndex,
                    portIndex
                  );
                },
                getExecJobForOutputPin(
                  nodeGroupIndex: number,
                  portIndex: number
                ) {
                  return [
                    nodeIdToExecute,
                    getOutputPortByIndex(
                      nodeToExecute,
                      nodeGroupIndex,
                      portIndex
                    ).Key,
                  ];
                },
              }
            );

            if (nextJob) {
              jobsToExecute.unshift(nextJob);
            }
          } else {
            const nextJob: ExecJob | void = getDefaultNextExecutionNodeId(
              nodeStateCache,
              typeTreeContext,
              nodeToExecute
            );

            if (nextJob) {
              jobsToExecute.unshift(nextJob);
            }
          }
        }
      } else {
        console.warn("Tried to execute non-available node");
      }
    }

    tickStatsProxy.executionsInLastTick = Math.max(
      executionContext.heat.currentHeat,
      tickStatsProxy.executionsInLastTick
    );
    tickStatsProxy.executionsScheduled = Math.max(
      executionContext.schedule.length,
      tickStatsProxy.executionsScheduled
    );
    tickStatsProxy.executionTime = Math.max(
      Date.now() - startTime,
      tickStatsProxy.executionTime
    );
  }, [
    nodeStateProxy,
    simulationSettings,
    tickStatsProxy,
    executionContext,
    editorContext,
    localContext,
    typeTreeContext,
  ]);

  useEffect(() => {
    const tickTimer = setInterval(() => {
      executeTick();
    }, SCHEDULER_INTERVAL);

    return () => {
      clearInterval(tickTimer);
    };
  }, [executeTick]);

  return null;
}
