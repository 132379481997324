import {
  CV2NodeData,
  CircuitObjectTransformData,
  NodeConnectionData,
  NodeId,
  PortId,
  KeyedOutputPortData,
} from "circuitsv2/circuitsv2-types";
import { INodeStateCache } from "pages/editor-page/editor-page-context/editor-page-context";
import {
  getAllOutputsForNode,
  getAllInputsForNode,
} from "circuitsv2/chips/chip-utils";

export function getNodeById(nodeStateCache: INodeStateCache, nid: NodeId) {
  const fromCache = nodeStateCache.nodeMap[nid];

  if (fromCache) {
    return fromCache;
  }

  for (const node of nodeStateCache.original.context.NodeDatas || []) {
    if (node.NodeId === nid) {
      return (nodeStateCache.nodeMap[nid] = node);
    }
  }
}

export function getInputPortById(node: CV2NodeData, portId: PortId) {
  const ports = getAllInputsForNode(node);
  return ports.find((input) => input.Key === portId);
}

export function getInputPortByIndex(
  node: CV2NodeData,
  nodeGroupIndex: number,
  portIndex: number
): KeyedOutputPortData {
  return node.NodeGroups![nodeGroupIndex].Value!.InputPorts![portIndex]!;
}

export function getOutputPortById(node: CV2NodeData, portId: PortId) {
  const ports = getAllOutputsForNode(node);
  return ports.find((input) => input.Key === portId);
}

export function getOutputPortByIndex(
  node: CV2NodeData,
  nodeGroupIndex: number,
  portIndex: number
): KeyedOutputPortData {
  return node.NodeGroups![nodeGroupIndex].Value!.OutputPorts![portIndex]!;
}

export function getConnectionsDatasForNode(
  nodeState: INodeStateCache,
  nodeId: NodeId
): NodeConnectionData[] {
  const fromCache = nodeState.nodeConnectionMap[nodeId];

  if (fromCache) {
    return fromCache;
  }

  return (nodeState.nodeConnectionMap[nodeId] =
    nodeState.original.context.Edges?.filter(
      (conn) => conn.SrcNodeId === nodeId || conn.DstNodeId === nodeId
    ) || []);
}

export function getConnectionsOnOutputPort(
  nodeState: INodeStateCache,
  nodeId: NodeId,
  portId: PortId
) {
  const fromCache = nodeState.portConnectionMap[portId];

  if (fromCache) {
    return fromCache;
  }

  return (nodeState.portConnectionMap[portId] = getConnectionsDatasForNode(
    nodeState,
    nodeId
  ).filter(
    (connection) =>
      connection.SrcNodeId === nodeId && connection.SrcPortId === portId
  ));
}

export function getConnectionsOnInputPort(
  nodeState: INodeStateCache,
  node: CV2NodeData,
  portId: PortId
) {
  const fromCache = nodeState.portConnectionMap[portId];

  if (fromCache) {
    return fromCache;
  }

  return (nodeState.portConnectionMap[portId] = getConnectionsDatasForNode(
    nodeState,
    node.NodeId
  ).filter(
    (connection) =>
      connection.DstNodeId === node.NodeId && connection.DstPortId === portId
  ));
}

export function getNodeTransformById(
  nodeState: INodeStateCache,
  nodeId: NodeId
): CircuitObjectTransformData | undefined {
  return getNodeById(nodeState, nodeId)?.TransformData;
}

export function getConnectionKey(connection: NodeConnectionData) {
  return `${connection.SrcPortId}-${connection.DstPortId}`;
}
