import { useErrorHandler } from "./dialogs";
import { useState } from "react";

export function useWrappedFetch(handleError: boolean = true) {
  const [isFetching, setIsFetching] = useState(false);
  const errorHandler = useErrorHandler();

  const wrappedFetch = async (input: RequestInfo, init?: RequestInit) => {
    setIsFetching(true);
    try {
      const response = await fetch(input, init);
      if (response.status !== 200) {
        throw new Error(await response.text());
      }
      return response;
    } catch (err) {
      if (handleError) {
        errorHandler(err);
      }
      throw err;
    } finally {
      setIsFetching(false);
    }
  };

  return [wrappedFetch, isFetching] as [typeof wrappedFetch, boolean];
}
