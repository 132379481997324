import {
  IChipImplementation,
  INodeEditorComponentProps,
} from "circuitsv2/chips/chip-types";

export const AddChip: IChipImplementation<void> = {
  getOutputData({ getInputData, node }) {
    let sum = 0;

    for (let i = 0; i < node.NodeGroups![0].Value.InputPorts!.length; i++) {
      sum += getInputData(0, i) || 0;
    }

    return sum;
    // return toInt32(
    //   bigInt(getInputData(EAddChipInputPorts.number1)).add(
    //     bigInt(getInputData(EAddChipInputPorts.number2))
    //   )
    // );
  },
  NodeEditorComponent({
    editorContext,
    node,
  }: INodeEditorComponentProps<void>): JSX.Element {
    function addPin() {
      editorContext.nodeOperations.addInputPort(node.NodeId, 0, {
        DescId: "dd3ec9e6-d22e-4e3e-bd63-f502bd558084",
      });
    }

    function removePin() {
      editorContext.nodeOperations.removeInputPort(
        node.NodeId,
        0,
        node.NodeGroups![0].Value!.InputPorts![
          node.NodeGroups![0].Value!.InputPorts!.length - 1
        ]
      );
    }

    const pinCount = node.NodeGroups![0].Value!.InputPorts!.length;

    return (
      <>
        {pinCount < 64 && <button onClick={addPin}>Add Pin</button>}
        {pinCount > 1 && <button onClick={removePin}>Remove Pin</button>}
      </>
    );
  },
};
