import {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  MutableRefObject,
  useRef,
} from "react";
import { PortId } from "circuitsv2/circuitsv2-types";
import { ICanvasTransform } from "pages/editor-page/editor-page-context/editor-page-context";
import { proxy } from "valtio";

type PortPositionMap = Record<PortId, { x: number; y: number }>;

export type ISelectedNodeProxy = {
  selected: string | null;
};

export interface INodeCanvasContext {
  portPositionsProxy: PortPositionMap;
  transformProxy: ICanvasTransform;
  svgRef: MutableRefObject<SVGSVGElement | undefined>;
  selectedNodeProxy: ISelectedNodeProxy;
}

export const NodeCanvasContext = createContext<INodeCanvasContext | undefined>(
  undefined
);

export function NodeCanvasContextProvider({
  children,
  transformProxy,
  selectedNodeProxy,
}: PropsWithChildren<{
  transformProxy: ICanvasTransform;
  selectedNodeProxy: ISelectedNodeProxy;
}>) {
  const [portPositionsProxy] = useState(() => proxy({}));

  const svgRef = useRef<SVGSVGElement>();

  return (
    <NodeCanvasContext.Provider
      value={{ portPositionsProxy, svgRef, transformProxy, selectedNodeProxy }}
    >
      {children}
    </NodeCanvasContext.Provider>
  );
}

export function useNodeCanvasContext(): INodeCanvasContext {
  const context = useContext(NodeCanvasContext);

  if (!context) {
    throw new Error("Missing NodeCanvasContext");
  }

  return context;
}
