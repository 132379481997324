import { useCallback, useEffect, useState } from "react";
import { getConnectionKey } from "./node/node-connection/util";
import { Spline } from "./node/node-connection/spline";
import { DragNode } from "./node/node";
import {
  IEditorPageContext,
  useEditorPageContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import {
  NodeId,
  NodeConnectionData,
  KeyedInputPortData,
  KeyedOutputPortData,
} from "circuitsv2/circuitsv2-types";
import { NodeConnection } from "./node/node-connection/node-connection";
import { validateNewConnection } from "circuitsv2/connection/connection";
import { useNodeCanvasContext } from "components/node-canvas/node-canvas-context/node-canvas-context";
import { useTypeTreeContext } from "circuitsv2/type-tree/type-tree-context";
import { useSnapshot } from "valtio";

export function NodeGraph({ transformProxy }) {
  const {
    nodeStateProxy,
    nodeStateCache,
    nodeOperations,
  }: IEditorPageContext = useEditorPageContext();

  const { portPositionsProxy } = useNodeCanvasContext();

  const [draggingSource, setDraggingSource] = useState<
    [NodeId, KeyedOutputPortData, number] | undefined
  >();
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const { svgRef } = useNodeCanvasContext();

  const { typeMapPorts } = useTypeTreeContext();

  const onMouseMove = useCallback(
    (e) => {
      let [pX, pY] = [e.clientX, e.clientY];
      e.stopPropagation();
      e.preventDefault();

      const svgRect = svgRef.current!.getBoundingClientRect();

      if (draggingSource) {
        setMousePos((old) => {
          return {
            ...old,
            ...{
              x: (pX - svgRect.left) / transformProxy.scale,
              y: (pY - svgRect.top) / transformProxy.scale,
            },
          };
        });
      }
    },
    [draggingSource, setMousePos, transformProxy, svgRef]
  );

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseMove]);

  const onMouseUp = useCallback(() => {
    setDraggingSource(undefined);
  }, []);

  const onStartConnection = useCallback(
    (nodeId: NodeId, port: KeyedInputPortData, outputIndex: number) => {
      setDraggingSource([nodeId, port, outputIndex]);

      const connectorStart = portPositionsProxy[port.Key] || {
        x: 0,
        y: 0,
      };

      setMousePos(connectorStart);
    },
    [setDraggingSource, portPositionsProxy]
  );

  const onCompleteConnection = useCallback(
    (dstNodeId: NodeId, port: KeyedInputPortData) => {
      console.log("complete");
      if (draggingSource) {
        const newConnection: NodeConnectionData = {
          SrcNodeId: draggingSource[0],
          SrcPortId: draggingSource[1].Key,
          DstNodeId: dstNodeId,
          DstPortId: port.Key,
          SrcNodeDescId: draggingSource[1].Value.DescId,
          DstNodeDescId: port.Value.DescId,
        };

        const validationMessage = validateNewConnection(
          nodeStateCache,
          newConnection,
          typeMapPorts
        );
        if (validationMessage === true) {
          nodeOperations.connect(newConnection);
        } else if (validationMessage === false) {
          nodeOperations.disconnect(newConnection);
        } else {
          console.warn("Connection not created: ", validationMessage);
          alert(validationMessage);
        }

        setDraggingSource(undefined);
      }
    },
    [
      nodeStateCache,
      nodeOperations,
      draggingSource,
      setDraggingSource,
      typeMapPorts,
    ]
  );

  let newConn: any = null;
  const nodeState = useSnapshot(nodeStateProxy);
  const portPositions = useSnapshot(portPositionsProxy);

  if (draggingSource) {
    const connectorStart = portPositions[draggingSource[1].Key] || {
      x: 0,
      y: 0,
    };

    const connectorEnd = {
      x: mousePos.x,
      y: mousePos.y,
    };

    newConn = (
      <Spline start={connectorStart} end={connectorEnd} color="#dadada" />
    );
  }

  const nodeDatas = nodeState.context.NodeDatas || [];

  return (
    <div
      className={"NodeGraph" + (draggingSource ? " dragging" : "")}
      onMouseUp={onMouseUp}
    >
      {nodeDatas.map((node) => {
        return (
          <DragNode
            key={node.NodeId}
            contextProxy={nodeStateProxy.context!}
            node={node}
            onStartConnector={onStartConnection}
            onCompleteConnector={onCompleteConnection}
          />
        );
      })}
      <svg
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          zIndex: 9000,
          left: 0,
          overflow: "visible",
        }}
        ref={svgRef as any}
      >
        {nodeState.context.Edges?.map((connection) => {
          return (
            <NodeConnection
              key={getConnectionKey(connection)}
              connection={connection}
            />
          );
        })}
        {newConn}
      </svg>
    </div>
  );
}
