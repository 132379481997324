import { NodePort } from "../../node-port/node-port";
import { makeStyles } from "@material-ui/core";
import {
  KeyedOutputPortData,
  CircuitContextData,
  CV2NodeData,
  KeyedNodeGroupData,
} from "circuitsv2/circuitsv2-types";
import { getOutputPortName } from "circuitsv2/chips/chip-utils";

interface INodeOutputProps {
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  nodeGroup: KeyedNodeGroupData;
  port: KeyedOutputPortData;
  onMouseDown: () => void;
}

const useStyles = makeStyles({
  nodeOutput: {
    color: "white",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    paddingTop: 3,
    paddingBottom: 3,
  },
  nodePort: {
    marginLeft: 5,
    marginRight: -13,
  },
});

export function NodeOutput({
  onMouseDown,
  contextProxy,
  node,
  nodeGroup,
  port,
}: INodeOutputProps) {
  const classes = useStyles();

  return (
    <li className={classes.nodeOutput}>
      {getOutputPortName(contextProxy, node, nodeGroup, port)}
      <div className={classes.nodePort}>
        <NodePort
          {...{
            onMouseDown,
            contextProxy,
            node,
            nodeGroup,
            port,
          }}
          isInput={false}
        />
      </div>
    </li>
  );
}
