import { NodeCanvasSplitter } from "components/node-canvas-splitter/node-canvas-splitter";
import { useCallback, useMemo, useState, useEffect } from "react";
import { Drawer, makeStyles, AppBar } from "@material-ui/core";
import {
  LeftSidebar,
  DRAWER_WIDTH,
  ESidebarTab,
} from "./left-sidebar/left-sidebar";
import {
  EditorPageContext,
  ICanvasTransform,
  IEditorPageContext,
  INodeOperations,
  ISimulationSettings,
  INodeState,
  INodeStateCache,
} from "./editor-page-context/editor-page-context";
import {
  CV2NodeData,
  CircuitObjectTransformData,
  NodeConnectionData,
  NodeId,
  InputPortData,
  KeyedInputPortData,
} from "circuitsv2/circuitsv2-types";
import { isEqual } from "lodash";
import { IShareBlob } from "pages/editor-page/left-sidebar/menus/share-menu";
import { TypeTreeContextProvider } from "circuitsv2/type-tree/type-tree-context";
import { proxy, subscribe } from "valtio";
import { v4 as uuid } from "uuid";
import { EditorToolbar } from "pages/editor-page/editor-toolbar";

const useStyles = makeStyles({
  drawerPaper: {
    zIndex: 800,

    marginTop: 64,
    height: "calc(100% - 64px)",
  },
});

export function EditorPage({ initialState }: { initialState: IShareBlob }) {
  const classes = useStyles();

  const [lastCanvasTransformProxy] = useState<ICanvasTransform>(() =>
    proxy({
      x: 0,
      y: 0,
      scale: 1,
      size: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      canvasSize: 1,
    })
  );

  const [numberOfCanvases, setNumberOfCanvases] = useState(1);

  const [
    simulationSettings,
    setSimulationSettings,
  ] = useState<ISimulationSettings>(initialState.simulationSettings);

  const [nodeStateProxy] = useState<INodeState>(() =>
    proxy(initialState.nodeState)
  );

  const [nodeStateCache] = useState<INodeStateCache>(() => ({
    nodeMap: {},
    nodeConnectionMap: {},
    portConnectionMap: {},
    original: nodeStateProxy,
  }));

  useEffect(() => {
    const unsubscribe = subscribe(nodeStateProxy.context, () => {
      console.log("flush cache");

      nodeStateCache.nodeMap = {};
      nodeStateCache.nodeConnectionMap = {};
      nodeStateCache.portConnectionMap = {};
    });

    return () => {
      unsubscribe();
    };
  }, [nodeStateProxy, nodeStateCache]);

  (window as any).nodeState = nodeStateProxy;

  const createNode = useCallback(
    (
      nodeData: Omit<CV2NodeData, "TransformData">,
      nodeTransform: CircuitObjectTransformData = {
        Id: nodeData.NodeId,
        LocalPosition: {
          X: lastCanvasTransformProxy.x,
          Y: lastCanvasTransformProxy.y,
        },
      }
    ) => {
      const newNode: CV2NodeData = {
        ...nodeData,
        TransformData: nodeTransform,
      };

      if (!nodeStateProxy.context.NodeDatas) {
        nodeStateProxy.context.NodeDatas = [];
      }

      nodeStateProxy.context.NodeDatas.push(newNode);
    },
    [nodeStateProxy, lastCanvasTransformProxy]
  );

  const moveNode = useCallback(
    (nodeId: NodeId, nodeTransform: CircuitObjectTransformData) => {
      const node = nodeStateProxy.context.NodeDatas?.find(
        (node) => node.NodeId === nodeId
      );

      if (node) {
        node.TransformData!.Id = nodeTransform.Id;
        node.TransformData!.LocalPosition = nodeTransform.LocalPosition;
        node.TransformData!.LocalRotation = nodeTransform.LocalRotation;
      } else {
        console.warn("node not found");
      }
    },
    [nodeStateProxy]
  );

  const addInputPort = useCallback(
    (nodeId: NodeId, nodeGroupIndex: number, port: InputPortData) => {
      const node = nodeStateProxy.context.NodeDatas?.find(
        (node) => node.NodeId === nodeId
      );

      if (
        node &&
        node.NodeGroups &&
        node.NodeGroups[nodeGroupIndex]?.Value?.InputPorts
      ) {
        node.NodeGroups[nodeGroupIndex].Value.InputPorts!.push({
          Key: uuid(),
          Value: port,
        });
      }
    },
    [nodeStateProxy]
  );

  const removeInputPort = useCallback(
    (nodeId: NodeId, nodeGroupIndex: number, port: KeyedInputPortData) => {
      const node = nodeStateProxy.context.NodeDatas?.find(
        (node) => node.NodeId === nodeId
      );

      if (nodeStateProxy.context.Edges) {
        nodeStateProxy.context.Edges = nodeStateProxy.context.Edges.filter(
          (edge) => edge.SrcPortId !== port.Key && edge.DstPortId !== port.Key
        );
      }

      if (
        node &&
        node.NodeGroups &&
        node.NodeGroups[nodeGroupIndex]?.Value?.InputPorts
      ) {
        node.NodeGroups[nodeGroupIndex].Value.InputPorts! = node.NodeGroups[
          nodeGroupIndex
        ].Value.InputPorts!.filter(({ Key }) => Key !== port.Key);
      }
    },
    [nodeStateProxy]
  );

  const removeNode = useCallback(
    (nodeId: string) => {
      if (nodeStateProxy.context.Edges) {
        nodeStateProxy.context.Edges = nodeStateProxy.context.Edges.filter(
          (edge) => edge.SrcNodeId !== nodeId && edge.DstNodeId !== nodeId
        );
      }

      if (nodeStateProxy.context.NodeDatas) {
        const nodeIndex = nodeStateProxy.context.NodeDatas.findIndex(
          (node) => node.NodeId === nodeId
        );

        if (nodeIndex !== -1) {
          nodeStateProxy.context.NodeDatas.splice(nodeIndex, 1);
        }
      }
    },
    [nodeStateProxy]
  );

  const connect = useCallback(
    (connection: NodeConnectionData) => {
      if (!nodeStateProxy.context.Edges) {
        nodeStateProxy.context.Edges = [];
      }

      nodeStateProxy.context.Edges.push(connection);
    },
    [nodeStateProxy]
  );

  const disconnect = useCallback(
    (connection: NodeConnectionData) => {
      if (nodeStateProxy.context.Edges) {
        nodeStateProxy.context.Edges = nodeStateProxy.context.Edges.filter(
          (c) => !isEqual(c, connection)
        );
      }
    },
    [nodeStateProxy]
  );

  const nodeOperations: INodeOperations = useMemo(
    () => ({
      createNode,
      moveNode,
      removeNode,
      addInputPort,
      removeInputPort,
      connect,
      disconnect,
    }),
    [
      createNode,
      moveNode,
      removeNode,
      addInputPort,
      removeInputPort,
      connect,
      disconnect,
    ]
  );

  const editorPageContext: IEditorPageContext = useMemo(
    () => ({
      nodeOperations,
      nodeStateProxy,
      nodeStateCache,
      lastCanvasTransformProxy,
      simulationSettings,
      setSimulationSettings,
      numberOfCanvases,
      setNumberOfCanvases,
    }),
    [
      nodeOperations,
      nodeStateProxy,
      nodeStateCache,
      lastCanvasTransformProxy,
      simulationSettings,
      setSimulationSettings,
      numberOfCanvases,
      setNumberOfCanvases,
    ]
  );

  const [sidebarTab, setSidebarTab] = useState<ESidebarTab>(
    () => ESidebarTab.chips
  );

  const toggleSidebar = useCallback(
    (tab: ESidebarTab) => {
      if (sidebarTab === tab) {
        setSidebarTab(ESidebarTab.close);
      } else {
        setSidebarTab(tab);
      }
    },
    [sidebarTab]
  );

  const isSidebarVisible = sidebarTab !== ESidebarTab.close;

  return (
    <div>
      <EditorPageContext.Provider value={editorPageContext}>
        <TypeTreeContextProvider>
          <AppBar position="static" variant="outlined">
            <EditorToolbar toggleSidebar={toggleSidebar} />
          </AppBar>
          <Drawer
            variant="persistent"
            open={isSidebarVisible}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <LeftSidebar sidebarTab={sidebarTab} />
          </Drawer>
          <div
            style={{
              position: "absolute",
              top: 64,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            <NodeCanvasSplitter
              numberOfCanvases={numberOfCanvases}
              vertical={true}
            />
          </div>
        </TypeTreeContextProvider>
      </EditorPageContext.Provider>
    </div>
  );
}
