import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { useEditorPageContext } from "pages/editor-page/editor-page-context/editor-page-context";
import { resolveTypeTree, TypeMap } from "circuitsv2/type-tree/type-tree";
import { useSnapshot } from "valtio";

export interface ITypeTreeContext {
  typeMapNodes: TypeMap;
  typeMapPorts: TypeMap;
}

export const TypeTreeContext = createContext<ITypeTreeContext | undefined>(
  undefined
);

export function TypeTreeContextProvider({
  children,
}: PropsWithChildren<Record<never, any>>) {
  const { nodeStateProxy } = useEditorPageContext();

  const nodeState = useSnapshot(nodeStateProxy);

  const { typeMapNodes, typeMapPorts } = useMemo(() => {
    return resolveTypeTree(nodeState);
  }, [nodeState]);

  return (
    <TypeTreeContext.Provider value={{ typeMapNodes, typeMapPorts }}>
      {children}
    </TypeTreeContext.Provider>
  );
}

export function useTypeTreeContext(): ITypeTreeContext {
  const context = useContext(TypeTreeContext);

  if (!context) {
    throw new Error("Missing TypeTreeContext");
  }

  return context;
}
