import { ConfirmProvider } from "material-ui-confirm";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CircuitPage } from "pages/circuit-page/circuit-page";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { theme } from "service/theme";

const useStyles = makeStyles({
  app: {
    textAlign: "center",
  },
});

export function App() {
  const classes = useStyles();

  console.log("Version: " + process.env.REACT_APP_GIT_SHA);

  return (
    <div className={classes.app}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route path="/circuit/:circuitId" component={CircuitPage} />
              <Route component={CircuitPage} />
            </Switch>
          </BrowserRouter>
        </ConfirmProvider>
      </ThemeProvider>
    </div>
  );
}
