import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  TextField,
  Grid,
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  INodeState,
  ISimulationSettings,
  useEditorPageContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import { useWrappedFetch } from "service/hooks/fetch";
import { runtimeConfig } from "service/runtimeConfig";
import html2canvas from "html2canvas";
import { useHistory } from "react-router";
import { useSharedCircuitContext } from "pages/circuit-page/shared-circuit-context";
import { useGetResource } from "service/hooks/resource-hooks";
import { getVersion } from "service/api";

export interface IShareBlob {
  nodeState: INodeState;
  simulationSettings: ISimulationSettings;
}

export function ShareMenu() {
  const { nodeStateProxy, simulationSettings } = useEditorPageContext();

  const history = useHistory();

  const [lastShareUrl, setLastShareUrl] = useState<string>("");
  const [wrappedFetch, isUploadingCircuit] = useWrappedFetch();

  const sharedCircuitContext = useSharedCircuitContext();

  const [title, setTitle] = useState(
    () => sharedCircuitContext.loaded?.sharedCircuit.name || ""
  );
  const [description, setDescription] = useState(
    () => sharedCircuitContext.loaded?.sharedCircuit.description || ""
  );

  async function handleShareCircuit() {
    function applyStyleToSvg(container: Element | Document) {
      for (let svg of Array.from(container.getElementsByTagName("svg"))) {
        for (let element of Array.from(
          svg.getElementsByTagName("*")
        ) as Array<SVGElement>) {
          const computedStyle = getComputedStyle(element);
          for (let property of Array.from(computedStyle)) {
            element.style.setProperty(
              property,
              computedStyle.getPropertyValue(property)
            );
          }
        }
      }
    }

    const thumbnailDataURL = (
      await html2canvas(document.body, {
        onclone: (clonedDoc) => applyStyleToSvg(clonedDoc),
        foreignObjectRendering: true,
        useCORS: true,
      })
    ).toDataURL("image/jpeg", 0.9);

    try {
      const response = await wrappedFetch(runtimeConfig.BLOB_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: JSON.stringify({
            nodeState: {
              context: nodeStateProxy.context,
            },
            simulationSettings,
          } as IShareBlob),
          thumbnail: thumbnailDataURL.replace(
            /^data:image\/(png|jpg|jpeg);base64,/,
            ""
          ),
          name: title,
          description,
          previousVersion: sharedCircuitContext.loaded?.loadedId,
        }),
      });
      const id = await response.text();

      setLastShareUrl(runtimeConfig.SHARE_BASE_URL + id);

      sharedCircuitContext.preventNextLoadRef.current.preventNextLoad = true;
      history.push("/circuit/" + id);
    } catch (err) {}
  }

  return isUploadingCircuit ? (
    <CircularProgress color="secondary" />
  ) : (
    <>
      <Typography variant="h5">Share Circuit</Typography>
      <Box p={2}>
        <Divider />
      </Box>

      <Grid container spacing={2} direction="column" justify="flex-end">
        <Grid item>
          <TextField
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
            label="Title (optional)"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
            label="Description (optional)"
            variant="outlined"
            multiline
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button color="inherit" onClick={handleShareCircuit}>
            Share
            {sharedCircuitContext.loaded?.loadedId ? " updated version" : ""}
          </Button>
        </Grid>
      </Grid>

      {lastShareUrl && (
        <>
          <Box p={2}>
            <Divider />
          </Box>

          <TextField
            value={lastShareUrl}
            label="Share URL"
            variant="outlined"
            onFocus={(ev) => ev.target.select()}
            fullWidth
          />
        </>
      )}

      {sharedCircuitContext.loaded?.sharedCircuit.previousVersion && (
        <>
          <Box p={2}>
            <Divider />
          </Box>

          <Typography>Previous Version(s):</Typography>

          <ResolveVersion
            versionId={
              sharedCircuitContext.loaded.sharedCircuit.previousVersion
            }
          />
        </>
      )}
    </>
  );
}

const versionCache = {};

function ResolveVersion({ versionId }) {
  const data = useGetResource(async () => {
    if (!versionCache[versionId]) {
      versionCache[versionId] = await getVersion(versionId);
    }

    return versionCache[versionId];
  }, [versionId]);

  const history = useHistory();

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <>
      <Button
        color="inherit"
        onClick={() => {
          history.push("/circuit/" + versionId);
        }}
      >
        {new Date(data.uploadedAt).toLocaleString()}
      </Button>
      {data.previousVersion && (
        <ResolveVersion versionId={data.previousVersion} />
      )}
    </>
  );
}
