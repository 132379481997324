import React from "react";
import { makeStyles } from "@material-ui/core";
import { ShareMenu } from "pages/editor-page/left-sidebar/menus/share-menu";
import { ChipsMenu } from "pages/editor-page/left-sidebar/menus/chips-menu";
import { SettingsMenu } from "pages/editor-page/left-sidebar/menus/settings-menu";

export const DRAWER_WIDTH = 250;

export enum ESidebarTab {
  close,
  chips,
  settings,
  share,
}

const useStyles = makeStyles({
  leftSidebar: {
    width: DRAWER_WIDTH,
    padding: 10,
    overflowY: "auto",
    zIndex: 800,
  },
});

export function LeftSidebar({ sidebarTab }) {
  const classes = useStyles();

  return (
    <div className={classes.leftSidebar}>
      {sidebarTab === ESidebarTab.chips && <ChipsMenu />}

      {sidebarTab === ESidebarTab.settings && <SettingsMenu />}

      {sidebarTab === ESidebarTab.share && <ShareMenu />}
    </div>
  );
}
