import {
  IconButton,
  Typography,
  Toolbar,
  makeStyles,
  Button,
  Link,
} from "@material-ui/core";
import { Add, Settings, Share } from "@material-ui/icons";
import { ESidebarTab } from "pages/editor-page/left-sidebar/left-sidebar";

const useStyles = makeStyles({
  toolbar: {
    zIndex: 900,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbarGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
});

export function EditorToolbar({ toggleSidebar }) {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarGroup}>
        <Link href="https://gitlab.com/rrcs" target="_blank" underline="none">
          <Button>
            <div
              style={{
                color: "white",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" style={{}}>
                RRCS V2
              </Typography>
              <Typography variant="caption" style={{ fontSize: 10 }}>
                Version: {process.env.REACT_APP_GIT_SHA}
              </Typography>
            </div>
          </Button>
        </Link>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => toggleSidebar(ESidebarTab.settings)}
          style={{ marginLeft: 10 }}
        >
          <Settings />
        </IconButton>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => toggleSidebar(ESidebarTab.chips)}
          style={{ marginLeft: 0 }}
        >
          <Add />
        </IconButton>
      </div>
      <div
        className={classes.toolbarGroup}
        style={{ justifyContent: "center" }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => toggleSidebar(ESidebarTab.share)}
          style={{ marginLeft: 0 }}
        >
          <Share />
        </IconButton>
      </div>
      <div
        className={classes.toolbarGroup}
        style={{ justifyContent: "flex-end" }}
      ></div>
    </Toolbar>
  );
}
