import { useConfirm } from "material-ui-confirm";

export function useErrorHandler() {
  const errorDialog = useErrorDialog();

  return (err: Error) => {
    console.error(err);
    return errorDialog(err.message);
  };
}

export function useErrorDialog() {
  const confirm = useConfirm();

  return (message: string, title: string = "Error") =>
    confirm({
      title,
      description: message,
      cancellationButtonProps: {
        style: { display: "none" },
      },
    });
}
