import { ENodeType } from "circuitsv2/circuitsv2-types";
import { hasChipImplementation } from "circuitsv2/chips/chip-database";
import { Tooltip } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

export function NodeImplementationWarning({
  nodeType,
}: {
  nodeType: ENodeType;
}) {
  if (hasChipImplementation(nodeType)) {
    return null;
  }

  return (
    <Tooltip title="Not implemented. Only visuals available.">
      <Warning style={{ color: "orange", fontSize: 13, marginRight: 3 }} />
    </Tooltip>
  );
}
