import { NodePort } from "../../node-port/node-port";
import { makeStyles } from "@material-ui/core";
import {
  KeyedInputPortData,
  KeyedNodeGroupData,
  CV2NodeData,
  CircuitContextData,
} from "circuitsv2/circuitsv2-types";
import { getInputPortName } from "circuitsv2/chips/chip-utils";

interface INodeInputProps {
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  nodeGroup: KeyedNodeGroupData;
  port: KeyedInputPortData;
  onMouseUp: () => void;
}

const useStyles = makeStyles({
  nodeInput: {
    color: "white",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    paddingTop: 3,
    paddingBottom: 3,
  },
  nodePort: {
    marginLeft: -13,
    marginRight: 5,
  },
});

export function NodeInput({
  onMouseUp,
  contextProxy,
  node,
  nodeGroup,
  port,
}: INodeInputProps) {
  const classes = useStyles();

  return (
    <li className={classes.nodeInput}>
      <div className={classes.nodePort}>
        <NodePort
          {...{
            onMouseUp,
            contextProxy,
            node,
            nodeGroup,
            port,
          }}
          isInput={true}
        />
      </div>
      {getInputPortName(contextProxy, node, nodeGroup, port)}
    </li>
  );
}
