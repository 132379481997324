import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import {
  KeyedInputPortData,
  KeyedOutputPortData,
  CircuitContextData,
  CV2NodeData,
  KeyedNodeGroupData,
  NodeDatabasePin,
  CircuitTypeData,
} from "circuitsv2/circuitsv2-types";
import useMeasure from "react-use-measure";
import { useEffect, useRef, useCallback } from "react";
import { useNodeCanvasContext } from "components/node-canvas/node-canvas-context/node-canvas-context";
import mergeRefs from "react-merge-refs";
import {
  getInputPortDesc,
  getOutputPortDesc,
  getNodeGroupDesc,
} from "circuitsv2/chips/chip-utils";
import {
  usePortType,
  getTypeDisplayName,
  ETypeKind,
  getTypeColor,
} from "circuitsv2/type-tree/type-utils";
import { useSnapshot } from "valtio";

interface INodePortProps<T> {
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  nodeGroup: KeyedNodeGroupData;
  port: T;
  isInput: boolean;
}

const useStyles = makeStyles({
  nodePort: ({ type }: { type: CircuitTypeData }) => {
    const baseStyle = {
      cursor: "cell",
      width: 20,
      height: 20,

      background: getTypeColor(type),

      borderRadius: "50%",
      border: "2px solid white",

      "&:hover": {
        filter: "brightness(150%)",
      },
    };

    switch (type.Kind) {
      case ETypeKind.exec:
        return {
          ...baseStyle,
          borderRadius: 0,
        };
    }

    return baseStyle;
  },
});

export function NodePort<T extends KeyedInputPortData | KeyedOutputPortData>({
  onMouseDown,
  onMouseUp,
  contextProxy,
  node,
  nodeGroup,
  port,
  isInput,
}: INodePortProps<T>) {
  const type = usePortType(port.Key);

  const classes = useStyles({ type });
  const localRef = useRef<HTMLDivElement>();
  const [measureRef, measure] = useMeasure({ debounce: 500 });
  const { portPositionsProxy, svgRef, transformProxy } = useNodeCanvasContext();

  const desc = isInput
    ? getInputPortDesc(contextProxy, node, nodeGroup, port)
    : getOutputPortDesc(contextProxy, node, nodeGroup, port);

  let portPositionX = 0;
  let portPositionY = 0;

  const transform = useSnapshot(transformProxy);

  if (localRef.current && svgRef.current) {
    const rectCanvas = svgRef.current.getBoundingClientRect();
    const rectLocal = localRef.current.getBoundingClientRect();

    portPositionX =
      (rectLocal.x +
        rectLocal.width / 2 -
        (rectCanvas.x + rectCanvas.width / 2)) /
      transform.scale;
    portPositionY =
      (rectLocal.y +
        rectLocal.height / 2 -
        (rectCanvas.y + rectCanvas.height / 2)) /
      transform.scale;
  }

  const handleOnMouseDown = useCallback(
    (e) => {
      if (onMouseDown) {
        onMouseDown();
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [onMouseDown]
  );

  const handleOnMouseUp = useCallback(
    (e) => {
      if (onMouseUp) {
        onMouseUp();
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [onMouseUp]
  );

  useEffect(() => {
    if (!portPositionsProxy[port.Key]) {
      portPositionsProxy[port.Key] = {
        x: portPositionX,
        y: portPositionY,
      };
    } else {
      portPositionsProxy[port.Key].x = portPositionX;
      portPositionsProxy[port.Key].y = portPositionY;
    }
  }, [port.Key, portPositionX, portPositionY, portPositionsProxy]);

  useEffect(() => {
    return () => {
      delete portPositionsProxy[port.Key];
    };
  }, [port.Key, portPositionsProxy]);

  return (
    <Tooltip
      title={<PortTooltip {...{ contextProxy, node, nodeGroup, port, desc }} />}
      placement="top"
    >
      <div
        ref={mergeRefs([measureRef as any, localRef])}
        className={classes.nodePort}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        onTouchStart={handleOnMouseDown}
        onTouchEnd={handleOnMouseUp}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
      />
    </Tooltip>
  );
}

function PortTooltip({
  contextProxy,
  node,
  nodeGroup,
  port,
  desc,
}: {
  contextProxy: CircuitContextData;
  node: CV2NodeData;
  nodeGroup: KeyedNodeGroupData;
  port: KeyedInputPortData | KeyedOutputPortData;
  desc: NodeDatabasePin;
}) {
  const type = usePortType(port.Key);

  const nodeDesc = getNodeGroupDesc(contextProxy, node, nodeGroup);
  const genericTypeDisplayName =
    nodeDesc.ReadonlyTypeParams?.[desc.ReadonlyType];

  return (
    <>
      <Typography>
        <b>Name:</b> {desc.Name || "-"}
      </Typography>
      <Typography>
        <b>Id:</b> {port.Key || "-"}
      </Typography>
      <Typography>
        <b>Type:</b>{" "}
        {desc.ReadonlyType +
          (genericTypeDisplayName ? " -> " + genericTypeDisplayName : "")}
      </Typography>
      <Typography>
        <b>Resolved:</b> {getTypeDisplayName(type)}
      </Typography>
      <Typography>
        <b>Description:</b> {desc.Description || "-"}
      </Typography>
    </>
  );
}
