import { IChipImplementation } from "circuitsv2/chips/chip-types";
import exp from "constants";

export const SinChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.sin(getInputData(0, 0) || 0);
  },
};

export const AsinChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.asin(getInputData(0, 0) || 0);
  },
};

export const CosChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.cos(getInputData(0, 0) || 0);
  },
};

export const AcosChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.acos(getInputData(0, 0) || 0);
  },
};

export const TanChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.tan(getInputData(0, 0) || 0);
  },
};

export const AtanChip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.atan(getInputData(0, 0) || 0);
  },
};

export const Atan2Chip: IChipImplementation<void> = {
  getOutputData({ portId, getInputData, node }) {
    return Math.atan2(getInputData(0, 0) || 0, getInputData(0, 1) || 0);
  },
};

export const TRIG_CHIP_IMPLEMENTATIONS = {
  "ea824326-5061-411d-980c-30031511c821": SinChip,
  "aaa4e58f-16df-426a-b7a7-a654eab97037": AsinChip,
  "e8659a59-9e11-4403-8bbc-f4a3961cbf21": CosChip,
  "46ce50b8-0a20-43d2-9646-484ce2a6752c": AcosChip,
  "13dbbd78-11c0-4b4f-9098-1ef59e584324": TanChip,
  "84646ed2-015e-4a8b-9d37-5115cb9ebadc": AtanChip,
  "02d62908-550d-4f8b-8bc7-0960fb1b547f": Atan2Chip,
};
