import { useRef, useState } from "react";
import { TrashIcon } from "./trash-icon";
import { useOnClickOutside } from "service/hooks/click";
import { makeStyles } from "@material-ui/core";
import { useNodeCanvasContext } from "components/node-canvas/node-canvas-context/node-canvas-context";

const useStyles = makeStyles({
  clickArea: {
    cursor: "pointer",
    fill: "none",
    stroke: "transparent",
    strokeWidth: 15,

    "&:hover": {
      cursor: "pointer",
      strokeWidth: 6,
    },
  },

  line: ({ selected, color }: { selected: boolean; color: string }) => ({
    fill: "none",
    stroke: selected ? "#337ab7" : color,
    strokeWidth: 4,

    "&:hover": {
      cursor: "pointer",
      strokeWidth: 6,
    },
  }),
});

export function Spline({
  onClick,
  onClickOutside,
  onRemove,
  end,
  start,
  color,
}: any) {
  const [selected, setSelected] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const classes = useStyles({ selected, color });

  const { svgRef, transformProxy } = useNodeCanvasContext();

  const handleClick = (e) => {
    setSelected((old) => !old);

    let [pX, pY] = [e.clientX, e.clientY];

    const svgRect = svgRef.current!.getBoundingClientRect();

    setPosition((old) => {
      return {
        ...old,
        ...{
          x: (pX - svgRect.left) / transformProxy.scale,
          y: (pY - svgRect.top) / transformProxy.scale,
        },
      };
    });

    if (onClick) {
      onClick(e);
    }
  };

  const ref = useRef<SVGGElement>(null);
  useOnClickOutside(ref, (e) => {
    setSelected(false);

    if (onClickOutside) {
      onClickOutside(e);
    }
  });

  const handleRemove = (e) => {
    setSelected(false);

    if (onRemove) {
      onRemove(e);
    }
  };

  let dist = distance([start.x, start.y], [end.x, end.y]);
  let pathString = bezierCurve(
    start.x,
    start.y,
    start.x + dist * 0.25,
    start.y,
    end.x - dist * 0.75,
    end.y,
    end.x,
    end.y
  );

  return (
    <g ref={ref}>
      <circle cx={start.x} cy={start.y} r={"3"} fill={"#337ab7"} />
      <circle cx={end.x} cy={end.y} r={"3"} fill={"#9191A8"} />
      <path
        className={classes.clickArea}
        d={pathString}
        onClick={handleClick}
      />
      <path className={classes.line} d={pathString} onClick={handleClick} />
      {selected ? (
        <TrashIcon position={position} onClick={handleRemove} />
      ) : null}
    </g>
  );
}

function bezierCurve(a, b, cp1x, cp1y, cp2x, cp2y, x, y) {
  return `M ${a} ${b} C ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${x} ${y}`;
}

function distance(a, b) {
  return Math.sqrt(
    (b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1])
  );
}
