import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  CircuitContextData,
  CV2NodeData,
  CircuitObjectTransformData,
  NodeConnectionData,
  NodeId,
  InputPortData,
  KeyedInputPortData,
  PortId,
} from "circuitsv2/circuitsv2-types";
import { ILocalExecutionContext } from "circuitsv2/context/local-context";

export interface INodeOperations {
  createNode(
    node: Omit<CV2NodeData, "TransformData">,
    transform?: CircuitObjectTransformData
  ): void;
  removeNode(nodeId: NodeId): void;
  moveNode(nodeId: NodeId, nodeTransfrom: CircuitObjectTransformData): void;
  addInputPort(
    nodeId: NodeId,
    nodeGroupIndex: number,
    port: InputPortData
  ): void;
  removeInputPort(
    nodeId: NodeId,
    nodeGroupIndex: number,
    port: KeyedInputPortData
  ): void;
  connect(connection: NodeConnectionData): void;
  disconnect(connection: NodeConnectionData): void;
}

export interface INodeStateCache {
  original: INodeState;

  nodeMap: Record<NodeId, CV2NodeData>;
  nodeConnectionMap: Record<NodeId, NodeConnectionData[]>;
  portConnectionMap: Record<PortId, NodeConnectionData[]>;
}

export interface INodeState {
  context: CircuitContextData;
}

export interface IAlertInterface {
  showMessage(message: string);
}

export interface ICanvasTransform {
  x: number;
  y: number;
  scale: number;
  canvasSize: number;
}

export interface ISimulationSettings {
  timeScale: number;
  maxHeat: number;
}

export interface IEditorPageContext {
  nodeOperations: INodeOperations;
  nodeStateProxy: INodeState;
  nodeStateCache: INodeStateCache;
  lastCanvasTransformProxy: ICanvasTransform;
  simulationSettings: ISimulationSettings;
  setSimulationSettings: Dispatch<SetStateAction<ISimulationSettings>>;
  numberOfCanvases: number;
  setNumberOfCanvases: Dispatch<SetStateAction<number>>;
}

export interface ITickStats {
  executionsInLastTick: number;
  executionsScheduled: number;
  executionTime: number;
}

export interface ILocalCircuitContext {
  executionContext: ILocalExecutionContext;
  alertInterface: IAlertInterface;
  tickStatsProxy: ITickStats;
}

export const EditorPageContext = createContext<IEditorPageContext | undefined>(
  undefined
);
export const LocalCircuitContext = createContext<
  ILocalCircuitContext | undefined
>(undefined);

export function useEditorPageContext(): IEditorPageContext {
  const context = useContext(EditorPageContext);

  if (!context) {
    throw new Error("Missing EditorPageContext");
  }

  return context;
}

export function useLocalCircuitContext(): ILocalCircuitContext {
  const context = useContext(LocalCircuitContext);

  if (!context) {
    throw new Error("Missing LocalCircuitContext");
  }

  return context;
}
