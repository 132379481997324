import { useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "service/hooks/dialogs";

export function useGetResource<T>(
  fetchFn: () => Promise<T>,
  deps: React.DependencyList = []
) {
  const [data, setData] = useState<T | undefined>();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    fetchFn()
      .then((data) => setData(data))
      .catch(errorHandler);
  }, deps);

  return data;
}

export function useManualRefreshDependency(): [number, () => void] {
  const [lastRefresh, setLastRefresh] = useState(Date.now());

  const refresh = useCallback((): void => {
    setLastRefresh(Date.now());
  }, [lastRefresh, setLastRefresh]);

  return [lastRefresh, refresh];
}
