import { NodeCanvas } from "../node-canvas/node-canvas";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { NodeContext } from "../node-context/node-context";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  nodeCanvasSplitter: {
    "& .layout-splitter": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

interface IProps {
  numberOfCanvases: number;
  vertical: boolean;
  nodeContextIndex?: number;
}

export function NodeCanvasSplitter({
  numberOfCanvases,
  vertical,
  nodeContextIndex = 0,
}: IProps) {
  const classes = useStyles();

  if (numberOfCanvases <= 1) {
    return (
      <NodeContext nodeContextIndex={nodeContextIndex}>
        <NodeCanvas />
      </NodeContext>
    );
  }

  return (
    <div className={classes.nodeCanvasSplitter}>
      <SplitterLayout vertical={vertical}>
        <NodeCanvasSplitter
          numberOfCanvases={Math.floor(numberOfCanvases / 2)}
          vertical={!vertical}
          nodeContextIndex={nodeContextIndex}
        />
        <NodeCanvasSplitter
          numberOfCanvases={Math.ceil(numberOfCanvases / 2)}
          vertical={!vertical}
          nodeContextIndex={nodeContextIndex + Math.floor(numberOfCanvases / 2)}
        />
      </SplitterLayout>
    </div>
  );
}
