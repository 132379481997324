import {
  CV2NodeData,
  NodeConnectionData,
  NodeId,
  PortId,
} from "../circuitsv2-types";
import {
  getConnectionsDatasForNode,
  getConnectionsOnInputPort,
  getConnectionsOnOutputPort,
  getInputPortById,
  getNodeById,
  getOutputPortById,
} from "components/node-canvas/node-graph/node/node-connection/util";
import { isEqual } from "lodash";
import {
  INodeState,
  INodeStateCache,
} from "pages/editor-page/editor-page-context/editor-page-context";
import { getAllOutputsForNode } from "circuitsv2/chips/chip-utils";
import { ETypeKind, INVALID_TYPE } from "circuitsv2/type-tree/type-utils";
import { mergeTypes, TypeMap } from "circuitsv2/type-tree/type-tree";
import { ITypeTreeContext } from "circuitsv2/type-tree/type-tree-context";

export function validateNewConnection(
  nodeState: INodeStateCache,
  potentialConnection: NodeConnectionData,
  typeMapPorts: TypeMap
): boolean | string {
  // TODO: Validate connections!

  const fromNode = getNodeById(nodeState, potentialConnection.SrcNodeId);
  const toNode = getNodeById(nodeState, potentialConnection.DstNodeId);

  const fromPortType =
    typeMapPorts[potentialConnection.SrcPortId] || INVALID_TYPE;
  const toPortType =
    typeMapPorts[potentialConnection.DstPortId] || INVALID_TYPE;

  if (!fromNode || !toNode) {
    return "Could not find nodes!";
  }

  if (
    getConnectionsDatasForNode(nodeState, fromNode.NodeId).find((c) =>
      isEqual(c, potentialConnection)
    )
  ) {
    return false;
  }

  const fromPort = getOutputPortById(fromNode, potentialConnection.SrcPortId);
  const toPort = getInputPortById(toNode, potentialConnection.DstPortId);

  if (!fromPort || !toPort) {
    return "Port not found";
  }

  if (mergeTypes(fromPortType, toPortType).Kind === ETypeKind.invalid) {
    return "Types are incompatible";
  }

  if (toPortType.Kind === ETypeKind.exec) {
    if (
      getConnectionsOnOutputPort(
        nodeState,
        fromNode.NodeId,
        potentialConnection.SrcPortId
      ).length
    ) {
      return "Only one connection exiting from Execute Pin allowed";
    }
  } else {
    if (
      getConnectionsOnInputPort(
        nodeState,
        toNode,
        potentialConnection.DstPortId
      ).length
    ) {
      return "Only one incoming connection allowed on data pin";
    }
  }

  return true;
}

export function getDefaultNextExecutionNodeId(
  nodeState: INodeStateCache,
  typeTreeContext: ITypeTreeContext,
  node: CV2NodeData
): [NodeId, PortId] | undefined {
  const ports = getAllOutputsForNode(node);
  for (const connection of getConnectionsDatasForNode(nodeState, node.NodeId)) {
    const outPort = ports.find((port) => port.Key === connection.SrcPortId)!;
    const outPortType = typeTreeContext.typeMapPorts[outPort.Key];

    if (
      connection.SrcNodeId === node.NodeId &&
      outPortType === ETypeKind.exec
    ) {
      return [connection.DstNodeId, connection.DstPortId];
    }
  }
}
