import {
  Typography,
  TextField,
  Tooltip,
  Button,
  Box,
  Divider,
} from "@material-ui/core";
import React, { useState, Fragment } from "react";
import {
  useEditorPageContext,
  INodeOperations,
} from "pages/editor-page/editor-page-context/editor-page-context";
import { ENodeType } from "circuitsv2/circuitsv2-types";
import {
  getNodeGroupsForNewChip,
  ROOT_LEVEL_CATEGORIES,
  NODE_DATABASE,
  getNodeTypeDisplayName,
  getRootLevelCategory,
  getNodeTypeDescription,
  getNodeFilters,
} from "circuitsv2/chips/chip-database";
import { NodeImplementationWarning } from "components/node-canvas/node-graph/node-implementation-warning";
import { makeId } from "service/uuid";

export function ChipsMenu() {
  const [search, setSearch] = useState("");

  const { nodeOperations } = useEditorPageContext();

  return (
    <>
      <Typography variant="h5">Add Nodes</Typography>
      <Box p={2}>
        <Divider />
      </Box>

      <TextField
        value={search}
        variant="outlined"
        placeholder="Search"
        onChange={(ev) => setSearch(ev.target.value)}
        autoFocus={true}
      />
      <CircuitButtons nodeOperations={nodeOperations} search={search} />
    </>
  );
}

const CircuitButtons = React.memo(CircuitButtonsRaw);
function CircuitButtonsRaw({
  nodeOperations,
  search,
}: {
  nodeOperations: INodeOperations;
  search: string;
}) {
  function spawnNode(type: ENodeType) {
    const newNodeId = makeId();

    nodeOperations.createNode({
      NodeId: newNodeId,
      NodeType: type,
      NodeGroups: getNodeGroupsForNewChip(type),
    });
  }

  return (
    <>
      {ROOT_LEVEL_CATEGORIES.map((category) => {
        const filteredNodes = Object.keys(NODE_DATABASE).filter((nd) => {
          const displayName = getNodeTypeDisplayName(nd);

          if (getRootLevelCategory(NODE_DATABASE[nd]) !== category) {
            return false;
          }

          if (
            search &&
            displayName.toLowerCase().indexOf(search.toLowerCase()) === -1
          ) {
            return false;
          }

          return true;
        });

        if (!filteredNodes.length) {
          return null;
        }

        return (
          <Fragment key={category}>
            <Typography variant="h6">{category || "Uncategorized"}</Typography>
            {filteredNodes.map((nodeType: any) => {
              const displayName = getNodeTypeDisplayName(nodeType);

              return (
                <Tooltip
                  key={nodeType}
                  title={
                    <>
                      <p>{getNodeTypeDescription(nodeType)}</p>
                      <p>
                        Filters:{" "}
                        {getNodeFilters(nodeType)
                          .map((nf) => nf.join(">"))
                          .join(", ")}
                      </p>
                    </>
                  }
                  placement="top"
                >
                  <Button
                    variant="outlined"
                    key={nodeType}
                    onClick={() => spawnNode(nodeType)}
                  >
                    <NodeImplementationWarning nodeType={nodeType} />
                    {displayName}
                  </Button>
                </Tooltip>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
}
