import {
  CircuitContextData,
  CV2NodeData,
  KeyedInputPortData,
  KeyedNodeGroupData,
  KeyedOutputPortData,
  InputDescData,
  OutputDescData,
  NodeDatabaseDesc,
  NodeDatabasePin,
  PortId,
} from "circuitsv2/circuitsv2-types";
import { NODE_DATABASE } from "circuitsv2/chips/chip-database";

export function getNodeDesc(context: CircuitContextData, node: CV2NodeData) {
  const databaseEntry = NODE_DATABASE[node.NodeType];

  if (!databaseEntry) {
    console.error(context, node);
    throw new Error("node desc not found");
  }
  return databaseEntry;
}

export function getNodeName(context: CircuitContextData, node: CV2NodeData) {
  const desc = getNodeDesc(context, node);

  return node.NodeName || desc.ReadonlyName || node.NodeType;
}

export function getNodeGroupDesc(
  context: CircuitContextData,
  node: CV2NodeData,
  nodeGroup: KeyedNodeGroupData
) {
  const databaseEntry = getNodeDesc(context, node).NodeDescs[nodeGroup.Key];

  if (!databaseEntry) {
    console.error(context, node, nodeGroup);
    throw new Error("node group desc not found");
  }

  return databaseEntry;
}

export function getInputPortDesc(
  context: CircuitContextData,
  node: CV2NodeData,
  nodeGroup: KeyedNodeGroupData,
  port: KeyedInputPortData
): NodeDatabasePin {
  const desc = getNodeGroupDesc(context, node, nodeGroup);

  if (!desc.InputPorts[port.Value.DescId]) {
    console.error(context, node, nodeGroup, desc, port);
    throw new Error("port desc not found");
  }

  return desc.InputPorts[port.Value.DescId];
}

export function getOutputPortDesc(
  context: CircuitContextData,
  node: CV2NodeData,
  nodeGroup: KeyedNodeGroupData,
  port: KeyedInputPortData
): NodeDatabasePin {
  const desc = getNodeGroupDesc(context, node, nodeGroup);

  if (!desc.OutputPorts[port.Value.DescId]) {
    console.error(context, node, nodeGroup, desc, port);
    throw new Error("port desc not found");
  }

  return desc.OutputPorts[port.Value.DescId];
}

export function getInputPortName(
  context: CircuitContextData,
  node: CV2NodeData,
  nodeGroup: KeyedNodeGroupData,
  port: KeyedInputPortData
) {
  const desc = getInputPortDesc(context, node, nodeGroup, port);

  return desc.Name;
}

export function getOutputPortName(
  context: CircuitContextData,
  node: CV2NodeData,
  nodeGroup: KeyedNodeGroupData,
  port: KeyedInputPortData
) {
  const desc = getOutputPortDesc(context, node, nodeGroup, port);

  return desc.Name;
}

export function getAllInputsForNode(node: CV2NodeData): KeyedInputPortData[] {
  const ports: KeyedInputPortData[] = [];

  for (const nodeGroup of node.NodeGroups || []) {
    ports.push(...(nodeGroup.Value.InputPorts || []));
  }

  return ports;
}

export function getAllOutputsForNode(node: CV2NodeData): KeyedOutputPortData[] {
  const ports: KeyedInputPortData[] = [];

  for (const nodeGroup of node.NodeGroups || []) {
    ports.push(...(nodeGroup.Value.OutputPorts || []));
  }

  return ports;
}

export function isOutputPort(node: CV2NodeData, portId: PortId) {
  return getAllOutputsForNode(node).find((p) => p.Key === portId);
}
