import { CircuitTypeData, PortId } from "circuitsv2/circuitsv2-types";
import { useTypeTreeContext } from "circuitsv2/type-tree/type-tree-context";

export enum ETypeKind {
  unkonwn_0 = 0,
  bool = 1,
  int = 2,
  float = 3,
  string = 4,
  exec = 5,
  unkonwn_6 = 6,

  // has a ClassType next to it (or defaults to "CreationObject")
  class = 10,

  list = 12,

  // 90% sure
  generic = 14,

  // custom
  any = 999,
  invalid = -1,
}

export const INVALID_TYPE: CircuitTypeData = {
  Kind: ETypeKind.invalid,
};

export enum ECV2ClassTypeName {
  vector3 = "s2IjynZN902amIv6PhTwgA==",
  rotator = "B40PxBuiBUyG0cEi4lUaSw==",

  // todo check
  list = "jpcRa0mb1EiXqLOe6tpKSA==",

  rrobject = "rrobject",
  ai = "ai",
  combatant = "combatant",
  patrol_point = "patrol_point",
  text = "text",
  button = "button",
  toggle_button = "toggle_button",
  player = "player",
  piston = "piston",
  emitter = "emitter",
}

export function parseType(
  readonlyType: string,
  genericMap: Record<string, string>
): CircuitTypeData {
  readonlyType = readonlyType.trim();

  switch (readonlyType) {
    case "exec":
      return {
        Kind: ETypeKind.exec,
      };
    case "bool":
      return {
        Kind: ETypeKind.bool,
      };
    case "int":
      return {
        Kind: ETypeKind.int,
      };
    case "float":
      return {
        Kind: ETypeKind.float,
      };
    case "string":
      return {
        Kind: ETypeKind.string,
      };
    case "any":
      return {
        Kind: ETypeKind.any,
      };
    case "Vector":
    case "Vector3":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.vector3,
        },
      };
    case "AI":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.ai,
        },
      };
    case "Combatant":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.combatant,
        },
      };
    case "Rec Room Object":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.rrobject,
        },
      };
    case "Patrol Point":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.patrol_point,
        },
      };
    case "Text":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.text,
        },
      };
    case "Button":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.button,
        },
      };
    case "Toggle Button":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.toggle_button,
        },
      };
    case "Player":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.player,
        },
      };
    case "Piston":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.piston,
        },
      };
    case "Rotator":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.rotator,
        },
      };
    case "Emitter":
      return {
        Kind: ETypeKind.class,
        ClassType: {
          Name: ECV2ClassTypeName.emitter,
        },
      };
  }

  if (readonlyType.startsWith("(") && readonlyType.endsWith(")")) {
    return {
      Kind: ETypeKind.generic,
      AppliedGenericType: {
        // TODO: original type?
        TypeParameterAssignments: readonlyType
          .substring(1, readonlyType.length - 1)
          .split("|")
          .map((t) => parseType(t, genericMap)),
      },
    };
  }

  if (["T", "T1", "T2"].includes(readonlyType)) {
    if (!genericMap[readonlyType]) {
      throw new Error("referenced generic type not found");
    }

    return {
      Kind: ETypeKind.generic,
      AppliedGenericType: {
        TypeParameterAssignments: [
          parseType(genericMap[readonlyType], genericMap),
        ],
      },
    };
  }

  if (readonlyType.startsWith("List<") && readonlyType.endsWith(">")) {
    return {
      Kind: ETypeKind.generic,
      AppliedGenericType: {
        OriginalType: {
          Kind: ETypeKind.class,
          ClassType: {
            Name: ECV2ClassTypeName.list,
            TypeParameters: [
              {
                Kind: ETypeKind.list,
                TypeParameterType: {
                  Name: "T",
                  Constraint: {},
                },
              },
            ],
          },
        },
        TypeParameterAssignments: [
          parseType(
            readonlyType.substring(5, readonlyType.length - 1),
            genericMap
          ),
        ],
      },
    };
  }

  throw new Error("could not parse type " + readonlyType);
}

export function usePortType(portId: PortId) {
  const { typeMapPorts } = useTypeTreeContext();

  return (
    typeMapPorts[portId] || {
      Kind: ETypeKind.unkonwn_0,
    }
  );
}

export function getTypeDisplayName(type: CircuitTypeData) {
  switch (type.Kind) {
    case ETypeKind.unkonwn_0:
      return "unkown_0";
    case ETypeKind.exec:
      return "exec";
    case ETypeKind.bool:
      return "bool";
    case ETypeKind.int:
      return "int";
    case ETypeKind.float:
      return "float";
    case ETypeKind.string:
      return "string";
    case ETypeKind.invalid:
      return "Invalid";
    case ETypeKind.any:
      return "any";
    case ETypeKind.class:
      switch (type.ClassType!.Name) {
        case ECV2ClassTypeName.vector3:
          return "Vector";
        case ECV2ClassTypeName.list:
          return "List";
        default:
          return "class " + type.ClassType?.Name;
      }

    case ETypeKind.generic:
      const assignments = type.AppliedGenericType!.TypeParameterAssignments!.map(
        getTypeDisplayName
      );
      const joinedAssignments = assignments.join(" | ");

      if (type.AppliedGenericType!.OriginalType) {
        return (
          getTypeDisplayName(type.AppliedGenericType!.OriginalType) +
          "<" +
          joinedAssignments +
          ">"
        );
      }

      return joinedAssignments;
  }

  return "missing display name: " + JSON.stringify(type);
}

export function getTypeColor(type: CircuitTypeData) {
  switch (type.Kind) {
    case ETypeKind.exec:
      return "#ff7508";
    case ETypeKind.bool:
      return "#ff0640";
    case ETypeKind.int:
      return "#11bc42";
    case ETypeKind.float:
      return "#98c4ea";
    case ETypeKind.string:
      return "#ff4fee";
    case ETypeKind.class:
      return "#fff258";
    case ETypeKind.invalid:
      return "#ff0000";
    case ETypeKind.generic:
      return "#919191";
    default:
      return "#a333b7";
  }
}
