import { useCallback, useEffect, useRef, useState } from "react";
import {
  ICanvasTransform,
  useEditorPageContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import { NodeGraph } from "./node-graph/node-graph";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import useMeasure from "react-use-measure";
import normalizeWheel from "normalize-wheel";
import { makeStyles } from "@material-ui/core";

import GRID_BACKGROUND from "./assets/background.png";
import {
  NodeCanvasContextProvider,
  ISelectedNodeProxy,
} from "components/node-canvas/node-canvas-context/node-canvas-context";
import { proxy, useSnapshot } from "valtio";

const CANVAS_SIZE = 5000;

const useStyles = makeStyles({
  nodeCanvas: {
    width: "100% !important",
    height: "100% !important",
    minHeight: "100px !important",
    minWidth: "100px !important",

    background: `#fff url("${GRID_BACKGROUND}")`,
    backgroundSize: "500px 500px",

    overflow: "hidden",

    cursor: "no-drop",

    "& .react-draggable": {
      cursor: "grab",
    },

    "& .react-draggable-dragging": {
      cursor: "grabbing",
    },
  },
});

export function NodeCanvas() {
  const [measureRef, size] = useMeasure();

  const { lastCanvasTransformProxy } = useEditorPageContext();

  const classes = useStyles();

  const canvasRef = useRef<HTMLDivElement | null>(null);
  const wheelTargetRef = useRef<HTMLDivElement | null>(null);

  const [selectedNodeProxy] = useState(() =>
    proxy<ISelectedNodeProxy>({ selected: null })
  );

  const [transformProxy] = useState<ICanvasTransform>(() =>
    proxy({
      x: 0,
      y: 0,
      scale: 1,
      size,
      canvasSize: CANVAS_SIZE,
    })
  );

  const onDragCanvas = useCallback(
    (event: DraggableEvent, data: DraggableData): void | false => {
      if (canvasRef.current) {
        canvasRef.current.style.backgroundPositionX = data.x + "px";
        canvasRef.current.style.backgroundPositionY = data.y + "px";
      }
    },
    []
  );

  const onDragEndCanvas = useCallback(
    (event: DraggableEvent, data: DraggableData): void | false => {
      if (canvasRef.current) {
        canvasRef.current.style.backgroundPositionX = data.x + "px";
        canvasRef.current.style.backgroundPositionY = data.y + "px";
      }

      transformProxy.x = -(
        data.x / transformProxy.scale +
        CANVAS_SIZE / 2 -
        size.width / 2 / transformProxy.scale
      );
      transformProxy.y = -(
        data.y / transformProxy.scale +
        CANVAS_SIZE / 2 -
        size.height / 2 / transformProxy.scale
      );
      lastCanvasTransformProxy.x = transformProxy.x;
      lastCanvasTransformProxy.y = transformProxy.y;
    },
    [transformProxy, size]
  );

  const transform = useSnapshot(transformProxy);
  const bounds = CANVAS_SIZE * transform.scale;

  const onMouseWheel = useCallback(
    (event: any) => {
      const delta = -normalizeWheel(event.nativeEvent).pixelY;
      const scale = Math.max(
        0.05,
        Math.min(transformProxy.scale + delta * 0.001, 1)
      );
      const scaleDiff = scale - transformProxy.scale;
      const rect = wheelTargetRef.current!.getBoundingClientRect();

      //x position relative to canvas center
      const relativeMouseX =
        (event.clientX - rect.left) / transformProxy.scale -
        CANVAS_SIZE / 2 -
        transformProxy.x;
      const relativeMouseY =
        (event.clientY - rect.top) / transformProxy.scale -
        CANVAS_SIZE / 2 -
        transformProxy.y;

      transformProxy.x += (relativeMouseX * scaleDiff) / scale;
      transformProxy.y += (relativeMouseY * scaleDiff) / scale;
      transformProxy.scale = scale;

      lastCanvasTransformProxy.x = transformProxy.x;
      lastCanvasTransformProxy.y = transformProxy.y;
      lastCanvasTransformProxy.scale = transformProxy.scale;
    },
    [transformProxy, size]
  );

  const draggablePositionX =
    (-transform.x - CANVAS_SIZE / 2) * transform.scale + size.width / 2;
  const draggablePositionY =
    (-transform.y - CANVAS_SIZE / 2) * transform.scale + size.height / 2;

  return (
    <div
      ref={(ref) => {
        canvasRef.current = ref;
        measureRef(ref);
      }}
      className={classes.nodeCanvas}
      style={{
        width: "100%",
        height: "100%",
        backgroundPositionX: draggablePositionX + "px",
        backgroundPositionY: draggablePositionY + "px",
        backgroundSize: `${500 * transform.scale}px ${500 * transform.scale}px`,
      }}
    >
      <Draggable
        bounds={{
          left: -bounds + size.width / 2,
          right: size.width / 2,
          bottom: size.height / 2,
          top: -bounds + size.height / 2,
        }}
        cancel=".disable-canvas-draggable"
        position={{
          x: draggablePositionX,
          y: draggablePositionY,
        }}
        onDrag={onDragCanvas}
        onStart={undefined}
        onStop={onDragEndCanvas}
        // @ts-ignore
        nodeRef={wheelTargetRef as any}
        scale={1}
      >
        <div
          style={{
            width: bounds + "px",
            height: bounds + "px",
            outline: "1px solid black",
          }}
          onWheel={onMouseWheel}
          ref={wheelTargetRef}
        >
          <div
            style={{
              transform: `translate(${bounds / 2}px, ${bounds / 2}px) scale(${
                transform.scale
              })`,
              width: 1,
              height: 1,
            }}
          >
            <NodeCanvasContextProvider
              transformProxy={transformProxy}
              selectedNodeProxy={selectedNodeProxy}
            >
              <NodeGraph transformProxy={transformProxy} />
            </NodeCanvasContextProvider>
          </div>
        </div>
      </Draggable>
    </div>
  );
}
