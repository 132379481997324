import { EditorPage } from "../editor-page/editor-page";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { useWrappedFetch } from "service/hooks/fetch";
import { runtimeConfig } from "service/runtimeConfig";
import { CircularProgress } from "@material-ui/core";
import { IShareBlob } from "pages/editor-page/left-sidebar/menus/share-menu";
import {
  SharedCircuitContext,
  ISharedCircuitContext,
} from "pages/circuit-page/shared-circuit-context";
import { useManualRefreshDependency } from "service/hooks/resource-hooks";

export function CircuitPage() {
  const params = useParams<{ circuitId: string }>();

  const [wrappedFetch, isFetchingCircuit] = useWrappedFetch();

  const [initialState, setInitialState] = useState<IShareBlob | undefined>(
    undefined
  );

  const preventNextLoadRef = useRef({ preventNextLoad: false });
  const [manualRefreshDep, refreshPage] = useManualRefreshDependency();

  const [
    sharedCircuitContext,
    setSharedCircuitContext,
  ] = useState<ISharedCircuitContext>({ preventNextLoadRef });

  useEffect(() => {
    if (!preventNextLoadRef.current.preventNextLoad) {
      setInitialState(undefined);
      refreshPage();
    }
    preventNextLoadRef.current.preventNextLoad = false;

    if (params.circuitId) {
      wrappedFetch(runtimeConfig.BLOB_URL + params.circuitId).then(
        async (response) => {
          const sharedCircuit = await response.json();
          console.log("Downloading Circuit:", sharedCircuit.dataUrl);
          response = await wrappedFetch(sharedCircuit.dataUrl);

          const initialState = await response.json();

          setInitialState(initialState);

          setSharedCircuitContext({
            loaded: {
              sharedCircuit,
              initialState,
              loadedId: params.circuitId,
            },
            preventNextLoadRef,
          });
        }
      );
    } else {
      setInitialState({
        nodeState: {
          context: {
            NodeDatas: [],
            ChildContexts: [],
          },
        },
        simulationSettings: {
          timeScale: 1,
          maxHeat: 1000,
        },
      });

      setSharedCircuitContext({
        preventNextLoadRef,
      });
    }
  }, [params.circuitId]);

  useEffect(() => {
    if (sharedCircuitContext?.loaded?.sharedCircuit?.name) {
      document.title =
        "RRCS V2 | " + sharedCircuitContext?.loaded?.sharedCircuit?.name;
    }

    return () => {
      document.title = "RRCS V2";
    };
  }, [sharedCircuitContext?.loaded?.sharedCircuit?.name]);

  return !initialState ? (
    <CircularProgress />
  ) : (
    <SharedCircuitContext.Provider value={sharedCircuitContext}>
      <EditorPage key={manualRefreshDep} initialState={initialState} />
    </SharedCircuitContext.Provider>
  );
}
