import { createContext, useContext, MutableRefObject } from "react";
import { IShareBlob } from "pages/editor-page/left-sidebar/menus/share-menu";
import { ISharedCircuit } from "service/api";

export interface ISharedCircuitContext {
  loaded?: {
    sharedCircuit: ISharedCircuit;
    initialState: IShareBlob;
    loadedId: string;
  };
  preventNextLoadRef: MutableRefObject<{ preventNextLoad: boolean }>;
}

export const SharedCircuitContext = createContext<
  ISharedCircuitContext | undefined
>(undefined);

export function useSharedCircuitContext(): ISharedCircuitContext {
  const context = useContext(SharedCircuitContext);

  if (!context) {
    throw new Error("SharedCircuitContext required");
  }

  return context;
}
