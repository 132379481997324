import {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { getNewLocalContext } from "circuitsv2/context/local-context";
import {
  IAlertInterface,
  ILocalCircuitContext,
  ITickStats,
  LocalCircuitContext,
  useLocalCircuitContext,
} from "pages/editor-page/editor-page-context/editor-page-context";
import { makeStyles, Paper, SnackbarContent } from "@material-ui/core";
import { ChipScheduler } from "circuitsv2/scheduler/chip-scheduler";
import moment from "moment";
import { proxy, useSnapshot } from "valtio";
import { setInterval } from "timers";

const useStyles = makeStyles({
  nodeContextHeader: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 99999,
    display: "flex",
    flexDirection: "row",
  },

  nodeContextStats: {
    padding: 5,
    background: "white",
    textAlign: "left",
  },
});

export function NodeContext({
  children,
  nodeContextIndex,
}: PropsWithChildren<{ nodeContextIndex: number }>) {
  const localContext = useRef(getNewLocalContext());

  const classes = useStyles();

  const [tickStatsProxy] = useState<ITickStats>(() =>
    proxy({
      executionsInLastTick: 0,
      executionsScheduled: 0,
      executionTime: 0,
    })
  );

  const [showSnackbar, setSnackbar] = useState<boolean>(false);
  const [snackbarMessages, setSnackbarMessages] = useState<string[]>([]);

  const snackbarTimeout = useRef<number>(-1);

  const showMessage = useCallback((message: string) => {
    setSnackbar(true);

    setSnackbarMessages((prevMessages) => {
      if (prevMessages.length > 2) {
        prevMessages.shift();
      }
      return [...prevMessages, moment().format(" h:mm:ss:SSS ") + message];
    });

    clearTimeout(snackbarTimeout.current);
    snackbarTimeout.current = setTimeout(() => {
      setSnackbar(false);
      setSnackbarMessages([]);
    }, 3000) as any;
  }, []);

  const alertInterface: IAlertInterface = useMemo(
    () => ({
      showMessage,
    }),
    [showMessage]
  );

  const context: ILocalCircuitContext = useMemo(
    () => ({
      executionContext: localContext.current,
      alertInterface,
      tickStatsProxy,
    }),
    [alertInterface, tickStatsProxy]
  );

  return (
    <LocalCircuitContext.Provider value={context}>
      <ChipScheduler />

      <NodeContextHeader />

      {showSnackbar && (
        <SnackbarContent
          style={{ whiteSpace: "pre", textAlign: "left" }}
          message={snackbarMessages.join("\n")}
        />
      )}
      {children}
    </LocalCircuitContext.Provider>
  );
}

export function NodeContextHeader() {
  const classes = useStyles();

  const { tickStatsProxy } = useLocalCircuitContext();

  const [maxTickStats, setMaxTickStats] = useState<ITickStats>({
    executionsInLastTick: 0,
    executionsScheduled: 0,
    executionTime: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setMaxTickStats({
        executionsInLastTick: tickStatsProxy.executionsInLastTick,
        executionsScheduled: tickStatsProxy.executionsScheduled,
        executionTime: tickStatsProxy.executionTime,
      });

      tickStatsProxy.executionsInLastTick = 0;
      tickStatsProxy.executionsScheduled = 0;
      tickStatsProxy.executionTime = 0;
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [tickStatsProxy]);

  return (
    <div className={classes.nodeContextHeader}>
      <Paper className={classes.nodeContextStats}>
        Max in last 500ms:
        <br /> Executions: {maxTickStats.executionsInLastTick} <br />
        Executions Scheduled: {maxTickStats.executionsScheduled}
        <br />
        Execution Time: {maxTickStats.executionTime}ms
      </Paper>
    </div>
  );
}
