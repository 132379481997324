import { Typography, Slider, Box, Divider, Button } from "@material-ui/core";
import React, { useCallback } from "react";
import { useEditorPageContext } from "pages/editor-page/editor-page-context/editor-page-context";

export function SettingsMenu() {
  const { simulationSettings, setSimulationSettings } = useEditorPageContext();

  const onTimeScaleChange = useCallback(
    (event, value) => {
      setSimulationSettings((oldSettings) => ({
        ...oldSettings,
        timeScale: value,
      }));
    },
    [setSimulationSettings]
  );

  const onMaxHeatChange = useCallback(
    (event, value) => {
      setSimulationSettings((oldSettings) => ({
        ...oldSettings,
        maxHeat: value,
      }));
    },
    [setSimulationSettings]
  );

  const { numberOfCanvases, setNumberOfCanvases } = useEditorPageContext();

  return (
    <>
      <Typography variant="h5">Settings</Typography>
      <Box p={2}>
        <Divider />
      </Box>

      <Typography gutterBottom>Simulation Time Scale</Typography>
      <Slider
        value={simulationSettings!.timeScale}
        getAriaValueText={(value) => value + "x"}
        valueLabelDisplay="auto"
        step={0.01}
        min={0.1}
        max={4}
        onChange={onTimeScaleChange}
      />
      <Typography gutterBottom>Max Executions / Tick</Typography>
      <Slider
        value={simulationSettings!.maxHeat}
        getAriaValueText={(value) => value + "x"}
        valueLabelDisplay="auto"
        min={10}
        max={5000}
        onChange={onMaxHeatChange}
      />

      <Box p={2}>
        <Divider />
      </Box>

      {numberOfCanvases < 8 && (
        <Button onClick={() => setNumberOfCanvases((prevNo) => prevNo + 1)}>
          Add Player
        </Button>
      )}
      {numberOfCanvases > 1 && (
        <Button
          onClick={() =>
            setNumberOfCanvases((prevNo) => Math.max(1, prevNo - 1))
          }
        >
          Remove Player
        </Button>
      )}
    </>
  );
}
