import { INodeState } from "pages/editor-page/editor-page-context/editor-page-context";
import { CircuitTypeData, NodeId, PortId } from "circuitsv2/circuitsv2-types";
import {
  getNodeGroupDesc,
  getInputPortDesc,
  getOutputPortDesc,
} from "circuitsv2/chips/chip-utils";
import {
  parseType,
  ETypeKind,
  INVALID_TYPE,
} from "circuitsv2/type-tree/type-utils";

export type TypeMap = Record<string, CircuitTypeData>;

type GenericIndex = [NodeId, string, string];

export function resolveTypeTree(nodeState: INodeState) {
  const typeMapNodes: Record<
    NodeId,
    Record<string, Record<string, CircuitTypeData>>
  > = {};
  const typeMapPorts: Record<PortId, CircuitTypeData> = {};
  const typeMapPortsGenerics: Record<PortId, GenericIndex> = {};

  const genericPartitions: {
    ports: PortId[];
    generics: GenericIndex[];
    constraints: CircuitTypeData[];
  }[] = [];

  const context = nodeState.context;

  for (const node of context.NodeDatas || []) {
    typeMapNodes[node.NodeId] = {};

    for (const nodeGroup of node.NodeGroups || []) {
      const nodeDesc = getNodeGroupDesc(context, node, nodeGroup);

      typeMapNodes[node.NodeId][nodeGroup.Key] = {};

      if (nodeDesc.ReadonlyTypeParams) {
        for (const genericKey of Object.keys(nodeDesc.ReadonlyTypeParams)) {
          typeMapNodes[node.NodeId][nodeGroup.Key][genericKey] = parseType(
            nodeDesc.ReadonlyTypeParams[genericKey],
            {}
          );
        }
      }

      for (const inputPort of nodeGroup.Value.InputPorts || []) {
        const portDesc = getInputPortDesc(context, node, nodeGroup, inputPort);

        if (typeMapNodes[node.NodeId][nodeGroup.Key][portDesc.ReadonlyType]) {
          typeMapPortsGenerics[inputPort.Key] = [
            node.NodeId,
            nodeGroup.Key,
            portDesc.ReadonlyType,
          ];
        } else {
          typeMapPorts[inputPort.Key] = parseType(
            portDesc.ReadonlyType,
            nodeDesc.ReadonlyTypeParams
          );
        }
      }

      for (const outputPort of nodeGroup.Value.OutputPorts || []) {
        const portDesc = getOutputPortDesc(
          context,
          node,
          nodeGroup,
          outputPort
        );

        if (typeMapNodes[node.NodeId][nodeGroup.Key][portDesc.ReadonlyType]) {
          typeMapPortsGenerics[outputPort.Key] = [
            node.NodeId,
            nodeGroup.Key,
            portDesc.ReadonlyType,
          ];
        } else {
          typeMapPorts[outputPort.Key] = parseType(
            portDesc.ReadonlyType,
            nodeDesc.ReadonlyTypeParams
          );
        }
      }
    }
  }

  function findPartition(portId: PortId, genericIndex: GenericIndex) {
    for (const [index, partition] of genericPartitions.entries()) {
      if (partition.ports.includes(portId)) {
        return index;
      } else if (
        partition.generics.find(
          (g) =>
            g[0] === genericIndex[0] &&
            g[1] === genericIndex[1] &&
            g[2] === genericIndex[2]
        )
      ) {
        return index;
      }
    }

    throw new Error("partition not found");
  }

  for (const genericPortId of Object.keys(typeMapPortsGenerics)) {
    const genericIndex = typeMapPortsGenerics[genericPortId];

    const existing = genericPartitions.find((partition) =>
      partition.generics.find(
        (g) =>
          g[0] === genericIndex[0] &&
          g[1] === genericIndex[1] &&
          g[2] === genericIndex[2]
      )
    );

    if (existing) {
      existing.generics.push(genericIndex);
      existing.ports.push(genericPortId);
    } else {
      genericPartitions.push({
        generics: [genericIndex],
        ports: [genericPortId],
        constraints: [],
      });
    }
  }

  for (const edge of context.Edges || []) {
    const typeSrcSimple = typeMapPorts[edge.SrcPortId];
    const typeDstSimple = typeMapPorts[edge.DstPortId];

    const typeSrcGeneric = typeMapPortsGenerics[edge.SrcPortId];
    const typeDstGeneric = typeMapPortsGenerics[edge.DstPortId];

    if (typeSrcSimple && typeDstSimple) {
      const merged = mergeSimpleTypes(typeSrcSimple, typeDstSimple);

      // The src might be connected to valid types
      // typeMapPorts[edge.SrcPortId] = merged;
      typeMapPorts[edge.DstPortId] = merged;
    } else if (typeSrcSimple) {
      if (!typeDstGeneric) {
        throw new Error("unexpected");
      }

      const genericPartitionIndex = findPartition(
        edge.DstPortId,
        typeDstGeneric
      );

      genericPartitions[genericPartitionIndex].constraints.push(typeSrcSimple);
    } else if (typeDstSimple) {
      if (!typeSrcGeneric) {
        throw new Error("unexpected");
      }

      const genericPartitionIndex = findPartition(
        edge.SrcPortId,
        typeSrcGeneric
      );

      genericPartitions[genericPartitionIndex].constraints.push(typeDstSimple);
    } else {
      if (!typeSrcGeneric && !typeDstGeneric) {
        throw new Error("unexpected");
      }

      const genericPartitionIndexSrc = findPartition(
        edge.SrcPortId,
        typeSrcGeneric
      );
      const genericPartitionIndexDst = findPartition(
        edge.DstPortId,
        typeDstGeneric
      );

      const toPartition = genericPartitions[genericPartitionIndexSrc];
      const fromPartition = genericPartitions[genericPartitionIndexDst];

      toPartition.ports.push(...fromPartition.ports);
      toPartition.generics.push(...fromPartition.generics);
      toPartition.constraints.push(...fromPartition.constraints);

      genericPartitions.splice(genericPartitionIndexDst, 1);
    }
  }

  const constrainedGenerics = genericPartitions.map((partition) => {
    const gi0 = partition.generics[0];
    let merged: CircuitTypeData = typeMapNodes[gi0[0]][gi0[1]][gi0[2]];

    for (let i = 1; i < partition.generics.length; i++) {
      const gi = partition.generics[i];
      merged = mergeTypes(merged, typeMapNodes[gi[0]][gi[1]][gi[2]]);
    }

    for (const constraint of partition.constraints) {
      merged = mergeTypes(merged, constraint);
    }

    return merged;
  });

  // console.log(genericPartitions);
  // console.log(constrainedGenerics);

  for (const genericPortId of Object.keys(typeMapPortsGenerics)) {
    typeMapPorts[genericPortId] =
      constrainedGenerics[
        genericPartitions.findIndex((gp) => gp.ports.includes(genericPortId))
      ];
  }

  return { typeMapNodes, typeMapPorts };
}

export function mergeSimpleTypes(
  typeA: CircuitTypeData,
  typeB: CircuitTypeData
): CircuitTypeData {
  if (typeA.Kind === typeB.Kind) {
    if (JSON.stringify(typeA) !== JSON.stringify(typeB)) {
      return INVALID_TYPE;
    }

    return { ...typeA };
  }

  if (typeA.Kind === ETypeKind.any) {
    return { ...typeB };
  } else if (typeB.Kind === ETypeKind.any) {
    return { ...typeA };
  }

  return { ...INVALID_TYPE };
}

export function mergeSimpleTypesArray(
  typesA: CircuitTypeData[],
  typesB: CircuitTypeData[]
): CircuitTypeData[] {
  const intersection: CircuitTypeData[] = [];

  for (const typeA of typesA) {
    for (const typeB of typesB) {
      const merged = mergeSimpleTypes(typeA, typeB);

      if (merged.Kind !== ETypeKind.invalid) {
        intersection.push(merged);
      }
    }
  }

  return intersection;
}

export function mergeTypes(
  typeA: CircuitTypeData,
  typeB: CircuitTypeData
): CircuitTypeData {
  const isAGeneric = !!typeA.AppliedGenericType;
  const isBGeneric = !!typeB.AppliedGenericType;

  if (isAGeneric && isBGeneric) {
    if (
      JSON.stringify(typeA.AppliedGenericType!.OriginalType) !==
      JSON.stringify(typeB.AppliedGenericType!.OriginalType)
    ) {
      return { ...INVALID_TYPE };
    }

    const merged = mergeSimpleTypesArray(
      typeA.AppliedGenericType!.TypeParameterAssignments || [],
      typeB.AppliedGenericType!.TypeParameterAssignments || []
    );

    if (!merged.length) {
      return { ...INVALID_TYPE };
    } else if (merged.length === 1) {
      return merged[0];
    }

    return {
      Kind: ETypeKind.generic,
      AppliedGenericType: {
        TypeParameterAssignments: merged,
      },
    };
  } else if (isAGeneric || isBGeneric) {
    const simpleType = isAGeneric ? typeB : typeA;
    const genericType = isAGeneric ? typeA : typeB;

    if (genericType.AppliedGenericType!.OriginalType) {
      console.warn("this should not happen in the type tree");
      return { ...INVALID_TYPE };
    }

    const merged = mergeSimpleTypesArray(
      [simpleType],
      genericType.AppliedGenericType!.TypeParameterAssignments || []
    );

    if (!merged.length) {
      return { ...INVALID_TYPE };
    } else if (merged.length === 1) {
      return merged[0];
    }

    return {
      Kind: ETypeKind.generic,
      AppliedGenericType: {
        TypeParameterAssignments: merged,
      },
    };
  }

  return mergeSimpleTypes(typeA, typeB);
}
